export const User = {
  GET_MANAGERS: "GET_MANAGERS",
  GET_USERS: "GET_USERS",
  GET_FIELD_STAFF: "GET_FIELD_STAFF",
  GET_DEALERS: "GET_DEALERS",
  ADD_MANAGER_VIEW: "ADD_MANAGER_VIEW",
  ADD_USER_VIEW: "ADD_USER_VIEW",
  ADD_FIELD_STAFF_VIEW: "ADD_FIELD_STAFF_VIEW",
  ADD_DEALER_VIEW: "ADD_DEALER_VIEW",
  ADD_MANAGER: "ADD_MANAGER",
  ADD_USER: "ADD_USER",
  ADD_EDIT_FIELD_STAFF: "ADD_EDIT_FIELD_STAFF",
  ADD_EDIT_DEALER: "ADD_EDIT_DEALER",
  GENERATE_PASSWORD: "GENERATE_PASSWORD",
  DELETE_MANAGER: "DELETE_MANAGER",
  DELETE_USER: "DELETE_USER",
  DELETE_FIELD_STAFF: "DELETE_FIELD_STAFF",
  DELETE_DEALER: "DELETE_DEALER",
  GET_SAP_CODES: "GET_SAP_CODES",
  ADD_SAP_CODES: "ADD_SAP_CODES",
  UPDATE_MANAGER: "UPDATE_MANAGER",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_FIELD_STAFF: "UPDATE_FIELD_STAFF",
  UPDATE_DEALER: "UPDATE_DEALER",
  USER_SUCCESS: "USER_SUCCESS",
  ENQUIRY_VIEW: "ENQUIRY_VIEW",
  ADD_EDIT_ENQUIRY_USER: "ADD_EDIT_ENQUIRY_USER",
  GET_TIMESHEETS: "GET_TIMESHEETS",
};
