import React from "react";
import { useDispatch } from "react-redux";
import Container from "../../components/Container/Container";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import { resetPassword } from "../../actions/account";
import { UserButton } from "../../components/Button";
import { useParams } from "react-router";
import UserLink from "../../components/Link/UserLink";
import { RouteConstant } from "../../utils/routeConstant";
import { RegxConstant } from "../../utils/regxConstant";
import useToast from "../../hooks/useToast";

const validationSchema = {
  email: { required: true },
  password: {
    required: true,
    validator: {
      regEx: RegxConstant.PASSWORD,
      error:
        "Password must contain atleast 1 lowercase,1 uppercase,1 numeric and 1 special character([!@#$%^&*]).\n Password length must be 8 characters or longer.",
    },
  },
  confirmPassword: {
    required: true,
    validator: {
      regEx: RegxConstant.PASSWORD,
      error:
        "Password must contain atleast 1 lowercase,1 uppercase,1 numeric and 1 special character([!@#$%^&*]).\n Password length must be 8 characters or longer.",
    },
  },
};

export default (props) => {
  const dispatch = useDispatch();
  const { email: userEmail, token } = useParams();
  const { state, handleChange, errors, handleSubmit } = useForm(
    { email: userEmail },
    validationSchema,
    submit
  );
  const { showError } = useToast();

  const { email, password, confirmPassword } = state;

  function submit(payload) {
    if (payload.password != payload.confirmPassword) {
      showError("New password and confirm password must be same");
      return;
    }
    dispatch(resetPassword({ ...payload, token }));
  }

  return (
    <Container title="Reset Password">
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-6">
            <TextInput
              name="email"
              id="email"
              value={email}
              asterix
              placeholder="Enter Email"
              onChange={handleChange}
              errors={errors}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="password"
              id="password"
              type="password"
              value={password}
              asterix
              placeholder="Enter new password"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              asterix
              placeholder="Confirm password"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <UserButton
              type="submit"
              text="Submit"
              className="btn btn-primary btn-sm"
            />
            <UserLink
              className="mr-l-10 btn-sm"
              text="Back to Login"
              href={RouteConstant.LOGIN_SCREEN}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};
