import { takeLatest, call, put } from "redux-saga/effects";
import AccountAPI from "../api/AccountAPI";
import { Account } from "../actionTypes/account";
import callAPI from "./helper";
import auth from "../libs/auth";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";
import { RoleConstant } from "../utils/roleConstant";

function* authUser(action) {
  yield call(callAPI, AccountAPI.authUser, action, setResult);
}

function* forgotPassword(action) {
  yield call(callAPI, AccountAPI.forgotPassword, action);
}

function* resetPassword(action) {
  yield call(callAPI, AccountAPI.resetPassword, action, resetPasswordResult);
}

function* resetPasswordResult() {
  yield put(push(RouteConstant.LOGIN_SCREEN));
}

function* confirmAccount(action) {
  yield call(callAPI, AccountAPI.confirmAccount, action, action.cb);
}

function* signOut(action) {
  yield call(callAPI, AccountAPI.signOut, action, onSignOut);
}

function* impersonationView(action) {
  yield call(callAPI, AccountAPI.impersonationView, action, action.cb);
}

function* impersonateUser(action) {
  yield call(callAPI, AccountAPI.impersonateUser, action, setResult);
}

function* stopImpersonation(action) {
  yield call(callAPI, AccountAPI.stopImpersonation, action, setResult);
}

function* onSignOut(result) {
  yield put({ type: Account.SIGN_OUT_SUCCESS });
  yield put(push(RouteConstant.LOGIN_SCREEN));
}

function* setResult({ token, userData, ...rest }) {
  auth.signIn(token);
  yield put({ type: Account.AUTH_SUCCESS, result: { userData, ...rest } });
  if (userData?.role == RoleConstant.ENQUIRY_USER) {
    yield put(push(RouteConstant.ARTICLES));
  } else {
    yield put(push(RouteConstant.ORDERS));
  }
}

function* updateResult(result) {
  yield put({ type: Account.AUTH_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Account.AUTH_USER, authUser);
  yield takeLatest(Account.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(Account.RESET_PASSWORD, resetPassword);
  yield takeLatest(Account.CONFIRM_ACCOUNT, confirmAccount);
  yield takeLatest(Account.SIGN_OUT, signOut);
  yield takeLatest(Account.IMPERSONATION_VIEW, impersonationView);
  yield takeLatest(Account.IMPERSONATE_USER, impersonateUser);
  yield takeLatest(Account.STOP_IMPERSONATION, stopImpersonation);
}
