import React from "react";
import {
  AutoSizer,
  Column,
  Table,
  defaultTableRowRenderer,
} from "react-virtualized";
import "react-virtualized/styles.css";
import "./_table.scss";

export default ({ headers, data, onRowClick, rowId, gridRef }) => {
  const handleRowClick = ({ rowData }) => {
    onRowClick(rowData[rowId]);
  };

  const Cell = ({ cellData }) => {
    return <div>{cellData}</div>;
  };

  const Row = ({ rowData, className, ...rest }) => {
    return defaultTableRowRenderer({
      ...rest,
      rowData,
      className: `${className}${rowData?.selected ? " selected" : ""}`,
    });
  };

  return (
    <div className="tableWrapper virtualTable" ref={gridRef ? gridRef : null}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={headers ? headers.length * 100 + 27 : 0}
            containerStyle={{
              width: headers ? headers.length * 100 + 27 : 0,
              maxWidth: "initial",
            }}
            height={height - 30}
            headerHeight={50}
            rowHeight={100}
            rowCount={data ? data.length : 0}
            onRowClick={handleRowClick}
            rowGetter={({ index }) => data[index]}
            key={rowId}
            rowRenderer={Row}
          >
            {headers &&
              headers.map((h) => (
                <Column
                  label={h.text}
                  dataKey={h.key}
                  width={100}
                  flexShrink={0}
                  cellRenderer={Cell}
                />
              ))}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};
