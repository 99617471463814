import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import useDoubleClick from "use-double-click";

export const Footer = ({ children }) => {
  return <Card.Footer>{children}</Card.Footer>;
};

export default ({ children, className, onClick, onDoubleClick, footer }) => {
  const cardRef = useRef();

  useDoubleClick({
    onSingleClick: onClick,
    onDoubleClick,
    ref: cardRef,
    latency: 250,
  });

  return (
    <Card style={{ width: "18rem" }} className={className}>
      <Card.Body ref={cardRef}>{children}</Card.Body>
      {footer}
    </Card>
  );
};
