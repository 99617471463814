export const Account = {
  AUTH_USER: "AUTH_USER",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  CONFIRM_ACCOUNT: "CONFIRM_ACCOUNT",
  SIGN_OUT: "SIGN_OUT",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  IMPERSONATION_VIEW: "IMPERSONATION_VIEW",
  IMPERSONATE_USER: "IMPERSONATE_USER",
  STOP_IMPERSONATION: "STOP_IMPERSONATION",
};
