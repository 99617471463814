import { Setting } from "../actionTypes/setting";

export const getAppConstant = (payload) => ({
  type: Setting.GET_APP_CONSTANT,
  payload,
});

export const addAppConstant = (payload) => ({
  type: Setting.ADD_APP_CONSTANT,
  payload,
});

export const addEditUserSeasons = (payload) => ({
  type: Setting.ADD_EDIT_USER_SEASONS,
  payload,
});

export const sendFeedback = (payload) => ({
  type: Setting.SEND_FEEDBACK,
  payload,
});
