export const Order = {
  ORDER_VIEW: "ORDER_VIEW",
  GET_ORDERS: "GET_ORDERS",
  CREATE_ORDER: "CREATE_ORDER",
  GET_ORDER_USERS: "GET_ORDER_USERS",
  UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
  UPDATE_ORDER_QUANTITY: "UPDATE_ORDER_QUANTITY",
  APPROVE_ORDER: "APPROVE_ORDER",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  GET_CART_DETAILS: "GET_CART_DETAILS",
  CART_SUCCESS: "CART_SUCCESS",
  ORDER_SUCCESS: "ORDER_SUCCESS",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
};
