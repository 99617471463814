import React from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { downloadPdf } from "../../actions/common";
import { UserButton } from "../Button";

export default ({ filename, payload }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      downloadPdf({
        fileType: "PDF",
        filename,
        ...payload,
      })
    );
  };

  return (
    <UserButton
      text="Download PDF"
      className="btn btn-primary btn-sm"
      icon={<AiOutlineFilePdf />}
      onClick={onClick}
    />
  );
};
