import { fork } from "redux-saga/effects";
import account from "./account";
import user from "./user";
import article from "./article";
import order from "./order";
import setting from "./setting";
import common from "./common";
import job from "./job";
import registerUser from "./registerUser";

export default function* root() {
  yield fork(account);
  yield fork(user);
  yield fork(article);
  yield fork(order);
  yield fork(setting);
  yield fork(common);
  yield fork(job);
  yield fork(registerUser);
}
