import APIUtil from "./APIUtil";

class AccountAPI {
  authUser(payload) {
    return APIUtil.callPostAPI("api/Login/Login", payload);
  }

  forgotPassword(payload) {
    return APIUtil.callPostAPI("api/Login/ForgotPassword", payload);
  }

  resetPassword(payload) {
    return APIUtil.callPostAPI("api/Login/ResetPassword", payload);
  }

  confirmAccount(payload) {
    return APIUtil.callPostAPI("api/Login/ConfirmAccount", payload);
  }

  signOut(payload) {
    return APIUtil.callPostAPI("api/Login/Logout", payload);
  }

  impersonationView(payload) {
    return APIUtil.callPostAPI("api/Login/ImpersonationView", payload);
  }

  impersonateUser(payload) {
    return APIUtil.callPostAPI("api/Login/ImpersonateUser", payload);
  }

  stopImpersonation(payload) {
    return APIUtil.callPostAPI("api/Login/StopImpersonation", payload);
  }
}

export default new AccountAPI();
