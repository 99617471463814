import React, { useState } from "react";
import { useEffect } from "react";
import UserIdleTimer from "../../common/UserIdleTimer";
import UserHeader from "../../components/Header/UserHeader";
import { LeftMenu } from "../../components/Menu";
import useScreenSize from "../../hooks/useScreenSize";
import { ArticleFilter } from "../Common";
import FeedbackScreen from "../Setting/FeedbackScreen";

export default (props) => {
  const [showNav, setShowNav] = useState(false);
  const { isMobileView } = useScreenSize();

  useEffect(() => {
    setShowNav(!isMobileView);
  }, [isMobileView]);

  const onToggle = () => setShowNav(!showNav);

  const closeMenu = () => {
    setShowNav(false);
  };

  return (
    <>
      <UserHeader onToggle={onToggle} />
      {(showNav || !isMobileView) && (
        <div className="left-Container col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <LeftMenu closeMenu={closeMenu}>
            <ArticleFilter />
          </LeftMenu>
        </div>
      )}
      <UserIdleTimer />
      <FeedbackScreen />
    </>
  );
};
