import { Order } from "../actionTypes/order";

export const orderView = (payload) => ({
  type: Order.ORDER_VIEW,
  payload,
});

export const getOrders = (payload) => ({
  type: Order.GET_ORDERS,
  payload,
});

export const updateOrderStatus = (payload) => ({
  type: Order.UPDATE_ORDER_STATUS,
  payload,
});

export const createOrder = (payload) => ({
  type: Order.CREATE_ORDER,
  payload,
});

export const updateOrderQuantity = (payload) => ({
  type: Order.UPDATE_ORDER_QUANTITY,
  payload,
});

export const approveOrder = (payload) => ({
  type: Order.APPROVE_ORDER,
  payload,
});

export const getOrderUsers = (payload) => ({
  type: Order.GET_ORDER_USERS,
  payload,
});

export const addToCart = (payload) => ({
  type: Order.ADD_TO_CART,
  payload,
});

export const removeFromCart = (payload) => ({
  type: Order.REMOVE_FROM_CART,
  payload,
});

export const getCartDetails = (payload) => ({
  type: Order.GET_CART_DETAILS,
  payload,
});
