import React from "react";

export default ({ title, subTitle, children, style, className }) => {
  return (
    <div className={`${className ? className : ""} wrapper`} style={style}>
      <h2>{title}</h2>
      {subTitle && <h6 style={{ fontSize: 12 }}>{subTitle}</h6>}
      {children}
    </div>
  );
};
