import { User } from "../actionTypes/user";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case User.USER_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    case User.UPDATE_MANAGER:
      const { manager } = action.result;
      return {
        ...state,
        ...action.result,
        managers: addUpdateList(state.managers, manager, "userProfileId"),
      };
    case User.UPDATE_USER:
      const { user } = action.result;
      return {
        ...state,
        users: addUpdateList(state.users, user, "userProfileId"),
      };
    case User.UPDATE_FIELD_STAFF:
      const { fieldStaff } = action.result;
      return {
        ...state,
        fieldStaffList: addUpdateList(
          state.fieldStaffList,
          fieldStaff,
          "userProfileId"
        ),
      };
    case User.UPDATE_DEALER:
      const { dealer } = action.result;
      return {
        ...state,
        dealerList: addUpdateList(state.dealerList, dealer, "userProfileId"),
      };
    default:
      return { ...state };
  }
};

const addUpdateList = (list, row, key) => {
  const index = list.findIndex((c) => c[key] == row[key]);
  if (index > -1) {
    if (row.isDeActivated) {
      list[index] = { ...list[index], ...row };
    } else list[index] = row;
  } else {
    list.push(row);
  }

  return list.slice();
};
