import { takeLatest, call, put } from "redux-saga/effects";
import CommonAPI from "../api/CommonAPI";
import { Common } from "../actionTypes/common";
import callAPI from "./helper";
import { downloadFile } from "../libs/functions";

function* downloadPdf({ payload }) {
  yield call(callAPI, CommonAPI.exportToPDF, { payload }, setResult);
}

function* setResult({ base64String }, { filename }) {
  downloadFile(base64String, filename);
}

export default function* rootSaga() {
  yield takeLatest(Common.DOWNLOAD_PDF, downloadPdf);
}
