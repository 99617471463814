import { takeLatest, call, put } from "redux-saga/effects";
import UserAPI from "../api/UserAPI";
import { User } from "../actionTypes/user";
import callAPI from "./helper";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";

function* getManagers(action) {
  yield call(callAPI, UserAPI.getManagers, action, setResult);
}

function* getUsers(action) {
  yield call(callAPI, UserAPI.getUsers, action, setResult);
}

function* addManagerView(action) {
  yield call(callAPI, UserAPI.addManagerView, action, setResult);
}

function* addUserView(action) {
  yield call(callAPI, UserAPI.addUserView, action, setResult);
}

function* addEditManager(action) {
  yield call(callAPI, UserAPI.addEditManager, action, updateManager);
}

function* addEditUser(action) {
  yield call(callAPI, UserAPI.addEditUser, action, updateUser);
}

function* deleteManager(action) {
  yield call(callAPI, UserAPI.deleteManager, action, updateManager);
}

function* deleteUser(action) {
  yield call(callAPI, UserAPI.deleteUser, action, updateUser);
}

function* addSapCodes(action) {
  yield call(callAPI, UserAPI.addSapCodes, action, action.cb);
}

function* getSapCodes(action) {
  yield call(callAPI, UserAPI.getSapCodes, action, setResult, true);
}

function* enquiryView(action) {
  yield call(callAPI, UserAPI.enquiryView, action, setResult);
}

function* addEditEnquiryUser(action) {
  yield call(callAPI, UserAPI.addEditEnquiryUser, action, enquiryResult);
}

function* getFieldStaff(action) {
  yield call(callAPI, UserAPI.getFieldStaff, action, setResult);
}

function* addFieldStaffView(action) {
  yield call(callAPI, UserAPI.addFieldStaffView, action, setResult);
}

function* addEditFieldStaff(action) {
  yield call(callAPI, UserAPI.addEditFieldStaff, action, updateFieldStaff);
}

function* deleteFieldStaff(action) {
  yield call(callAPI, UserAPI.deleteFieldStaff, action, updateFieldStaff);
}

function* getDealers(action) {
  yield call(callAPI, UserAPI.getDealers, action, setResult);
}

function* addDealerView(action) {
  yield call(callAPI, UserAPI.addDealerView, action, setResult);
}

function* addEditDealer(action) {
  yield call(callAPI, UserAPI.addEditDealer, action, updateDealer);
}

function* deleteDealer(action) {
  yield call(callAPI, UserAPI.deleteDealer, action, updateDealer);
}

function* getTimesheets(action) {
  yield call(callAPI, UserAPI.getTimesheets, action, setResult);
}

function* enquiryResult(result, { mobileNo }) {
  yield put(push(RouteConstant.LOGIN_SCREEN, { userName: mobileNo }));
}

function* generatePassword(action) {
  yield call(callAPI, UserAPI.generatePassword, action, action.cb, true);
}

function* updateManager(result, payload, cb) {
  yield put({ type: User.UPDATE_MANAGER, result });
  if (cb) cb();
}

function* updateUser(result, payload, cb) {
  yield put({ type: User.UPDATE_USER, result });
  if (cb) cb();
}

function* updateFieldStaff(result, payload, cb) {
  yield put({ type: User.UPDATE_FIELD_STAFF, result });
  if (cb) cb();
}

function* updateDealer(result, payload, cb) {
  yield put({ type: User.UPDATE_DEALER, result });
  if (cb) cb();
}

function* setResult(result) {
  yield put({ type: User.USER_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(User.GET_MANAGERS, getManagers);
  yield takeLatest(User.GET_USERS, getUsers);
  yield takeLatest(User.ADD_MANAGER_VIEW, addManagerView);
  yield takeLatest(User.ADD_USER_VIEW, addUserView);
  yield takeLatest(User.ADD_MANAGER, addEditManager);
  yield takeLatest(User.ADD_USER, addEditUser);
  yield takeLatest(User.GENERATE_PASSWORD, generatePassword);
  yield takeLatest(User.DELETE_MANAGER, deleteManager);
  yield takeLatest(User.DELETE_USER, deleteUser);
  yield takeLatest(User.ADD_SAP_CODES, addSapCodes);
  yield takeLatest(User.GET_SAP_CODES, getSapCodes);
  yield takeLatest(User.ENQUIRY_VIEW, enquiryView);
  yield takeLatest(User.ADD_EDIT_ENQUIRY_USER, addEditEnquiryUser);
  yield takeLatest(User.ADD_FIELD_STAFF_VIEW, addFieldStaffView);
  yield takeLatest(User.GET_FIELD_STAFF, getFieldStaff);
  yield takeLatest(User.ADD_EDIT_FIELD_STAFF, addEditFieldStaff);
  yield takeLatest(User.DELETE_FIELD_STAFF, deleteFieldStaff);
  yield takeLatest(User.ADD_DEALER_VIEW, addDealerView);
  yield takeLatest(User.GET_DEALERS, getDealers);
  yield takeLatest(User.ADD_EDIT_DEALER, addEditDealer);
  yield takeLatest(User.DELETE_DEALER, deleteDealer);
  yield takeLatest(User.GET_TIMESHEETS, getTimesheets);
}
