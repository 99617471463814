import React from "react";
import "./_leftMenu.scss";
import { RouteConstant } from "../../utils/routeConstant";
import { useSelector } from "react-redux";
import UserLink from "../Link/UserLink";
import useUserDetail from "../../hooks/useUserDetail";

const {
  ORDERS,
  MANAGERS,
  USERS,
  ARTICLES,
  JOBS,
  FIELD_STAFF,
  DEALER,
  FIELD_STAFF_TIME_TABLE,
  OFFICE_STAFF_TIME_TABLE,
  IMPERSONATION,
} = RouteConstant;

const Menu = ({ href, menuName, closeMenu }) => {
  const {
    location: { pathname },
  } = useSelector((state) => state.router);

  return (
    <li
      className={`start ${pathname == href ? "active" : ""} open`}
      onClick={closeMenu}
    >
      <UserLink
        href={href}
        className=" "
        text={
          <>
            <i className="icon-home"></i>
            <span className="title">{menuName}</span>
            <span className="selected"></span>
          </>
        }
      />
    </li>
  );
};

export default ({ children, closeMenu }) => {
  const {
    isCoordinator,
    isUser,
    isAdmin,
    isEnquiryUser,
    isDistributor,
    isFieldStaff,
    showDealers,
    isUserManager,
  } = useUserDetail();

  return (
    <div className="page-sidebar-wrapper">
      <div className="page-sidebar navbar-collapse">
        <ul
          className="page-sidebar-menu page-sidebar-menu-light page-sidebar-menu-hover-submenu"
          data-keep-expanded="false"
          data-auto-scroll="true"
          data-slide-speed="200"
        >
          <li className="sidebar-toggler-wrapper">
            <div className="sidebar-toggler"></div>
          </li>
          {!isEnquiryUser && (
            <Menu href={ORDERS} menuName="Dashboard" closeMenu={closeMenu} />
          )}
          <Menu href={ARTICLES} menuName="Articles" closeMenu={closeMenu} />
          <li style={{ position: "relative" }}>{children}</li>
          {(isCoordinator || isAdmin) && (
            <>
              <Menu href={USERS} menuName="Users" closeMenu={closeMenu} />
              {!isCoordinator && (
                <Menu
                  href={MANAGERS}
                  menuName="Managers"
                  closeMenu={closeMenu}
                />
              )}
            </>
          )}
          {(isAdmin ||
            isCoordinator ||
            isFieldStaff ||
            isUserManager ||
            (isUser && isDistributor)) && (
            <>
              {!isFieldStaff && (
                <>
                  <Menu
                    href={FIELD_STAFF}
                    menuName="Field Staff"
                    closeMenu={closeMenu}
                  />
                  <Menu
                    href={FIELD_STAFF_TIME_TABLE}
                    menuName="Field Staff Timesheet"
                    closeMenu={closeMenu}
                  />
                  <Menu
                    href={OFFICE_STAFF_TIME_TABLE}
                    menuName="Staff Timesheet"
                    closeMenu={closeMenu}
                  />
                </>
              )}
              {showDealers && (
                <Menu href={DEALER} menuName="Dealers" closeMenu={closeMenu} />
              )}
            </>
          )}
          {isAdmin && (
            <>
              <Menu
                href={JOBS}
                menuName="Upload Articles"
                closeMenu={closeMenu}
              />
              <Menu
                href={IMPERSONATION}
                menuName="Impersonation"
                closeMenu={closeMenu}
              />
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
