import { takeLatest, call, put } from "redux-saga/effects";
import OrderAPI from "../api/OrderAPI";
import { Order } from "../actionTypes/order";
import callAPI from "./helper";
import { RouteConstant } from "../utils/routeConstant";
import { push } from "connected-react-router";

function* orderView(action) {
  yield call(callAPI, OrderAPI.orderView, action, setResult);
}

function* getOrders(action) {
  yield call(callAPI, OrderAPI.getOrders, action, setResult);
}

function* getOrderUsers(action) {
  yield call(callAPI, OrderAPI.getOrderUsers, action, setResult);
}

function* createOrder(action) {
  yield call(callAPI, OrderAPI.createOrder, action, createOrderResult);
}

function* addToCart(action) {
  yield call(callAPI, OrderAPI.addToCart, action, setCartResult, true);
}

function* removeFromCart(action) {
  yield call(callAPI, OrderAPI.removeFromCart, action, setCartResult, true);
}

function* getCartDetails(action) {
  yield call(callAPI, OrderAPI.getCartDetails, action, setCartResult);
}

function* updateOrderStatus(action) {
  yield call(callAPI, OrderAPI.updateOrderStatus, action, setResult);
}

function* updateOrderQuantity(action) {
  yield call(callAPI, OrderAPI.updateOrderQuantity, action, setResult);
}

function* approveOrder(action) {
  yield call(callAPI, OrderAPI.approveOrder, action, setResult);
}

function* createOrderResult(result) {
  yield put({ type: Order.CREATE_ORDER_SUCCESS, result });
  yield put(push(RouteConstant.ORDERS));
}

function* setResult(result) {
  yield put({ type: Order.ORDER_SUCCESS, result });
}

function* setCartResult(result) {
  yield put({ type: Order.CART_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Order.ORDER_VIEW, orderView);
  yield takeLatest(Order.GET_ORDERS, getOrders);
  yield takeLatest(Order.GET_ORDER_USERS, getOrderUsers);
  yield takeLatest(Order.UPDATE_ORDER_STATUS, updateOrderStatus);
  yield takeLatest(Order.CREATE_ORDER, createOrder);
  yield takeLatest(Order.UPDATE_ORDER_QUANTITY, updateOrderQuantity);
  yield takeLatest(Order.APPROVE_ORDER, approveOrder);
  yield takeLatest(Order.ADD_TO_CART, addToCart);
  yield takeLatest(Order.REMOVE_FROM_CART, removeFromCart);
  yield takeLatest(Order.GET_CART_DETAILS, getCartDetails);
}
