import { includes, uniqBy } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UserCheckbox } from "../../components/Checkbox";
import { MultiSelect } from "../../components/SelectBox";
import useForm from "../../hooks/useForm";
import useUserDetail from "../../hooks/useUserDetail";

export default ({
  channels,
  indicators,
  brands,
  viewArticleOnly,
  handleChange,
}) => {
  const initBrandList = useSelector((state) => state.user.brandList);
  const { isAdmin } = useUserDetail();
  const { state: filters, handleStateChange: setFilters } = useForm({});
  const { channelList, indList, brandList } = filters;

  useEffect(() => {
    if (initBrandList) {
      setFilters({
        channelList: uniqBy(initBrandList, (c) => c.channel).map((c) => ({
          text: c.channel,
          value: c.channel,
        })),
      });
    }
  }, [initBrandList]);

  useEffect(() => {
    if (initBrandList) {
      let indList = initBrandList;
      if (channels && channels.length > 0) {
        indList = initBrandList.filter((c) => includes(channels, c.channel));
      }
      setFilters({
        indList: uniqBy(indList, (c) => c.indicator).map((c) => ({
          text: c.indicator,
          value: c.indicator,
        })),
      });
    }
  }, [initBrandList, channels]);

  useEffect(() => {
    if (initBrandList) {
      let brandList = initBrandList;
      if (indicators && indicators.length > 0) {
        brandList = brandList.filter((c) => includes(indicators, c.indicator));
      }
      if (channels && channels.length > 0) {
        brandList = brandList.filter((c) => includes(channels, c.channel));
      }
      setFilters({
        brandList: uniqBy(brandList, (c) => c.brand).map((c) => ({
          text: c.brand,
          value: c.brand,
        })),
      });
    }
  }, [initBrandList, indicators, channels]);

  return (
    <>
      {isAdmin && (
        <>
          <div className="row">
            <div className="col-6">
              <MultiSelect
                name="channels"
                values={channels}
                options={channelList}
                onChange={handleChange}
                placeholder="Search Channels"
                hasValues
              />
            </div>
            <div className="col-6">
              <MultiSelect
                name="indicators"
                values={indicators}
                options={indList}
                onChange={handleChange}
                placeholder="Search Indicator"
                hasValues
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <MultiSelect
                name="brands"
                values={brands}
                options={brandList}
                onChange={handleChange}
                placeholder="Search Brands"
                hasValues
              />
            </div>
            <div className="col-6">
              <UserCheckbox
                name="viewArticleOnly"
                checked={viewArticleOnly}
                onChange={handleChange}
                label="View Articles"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
