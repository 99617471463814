import APIUtil from "./APIUtil";

class JobAPI {
  getJobList(payload) {
    return APIUtil.callPostAPI("api/Job/GetJobList", payload);
  }

  triggerJob(payload) {
    return APIUtil.callPostAPI("api/Job/TriggerJob", payload);
  }
}

export default new JobAPI();
