import { orderBy, sumBy, uniqBy } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, createOrder, removeFromCart } from "../../actions/order";
import { getSapCodes } from "../../actions/user";
import { UserButton } from "../../components/Button";
import { UserCheckbox } from "../../components/Checkbox";
import { Container } from "../../components/Container";
import { UserSelect } from "../../components/SelectBox";
import { UserTable } from "../../components/Table";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import useGrid from "../../hooks/useGrid";
import useToast from "../../hooks/useToast";
import { AppConstant } from "../../utils/constant";

const meta = [
  {
    key: "rowNo",
    text: "S.No",
  },
  {
    key: "select",
    text: "",
  },
  {
    key: "articleName",
    text: "Article",
    sort: true,
  },
  {
    key: "sizes",
    text: (
      <div className="row">
        <div className="col-5">
          Stock <br /> (* Subject to stock availability)
        </div>
        <div className="col-3">Size</div>
        <div className="col-4">Quantity</div>
      </div>
    ),
    sort: true,
  },
];

const Input = ({
  name,
  value,
  type,
  placeholder,
  handleChange,
  rowId,
  sizeList,
  size,
}) => {
  const onChange = (name, value) => {
    const index = sizeList.findIndex((c) => c.size == size);
    if (index > -1) {
      sizeList[index] = { ...sizeList[index], [name]: value };
    }
    handleChange(rowId, "orderSizes", sizeList);
  };

  return (
    <TextInput
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      minValue={1}
    />
  );
};

const Checkbox = ({ name, value, handleChange, rowId }) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return <UserCheckbox name={name} checked={value} onChange={onChange} />;
};

export default ({ articles, selectedUser, handleBack, updateCart }) => {
  const { gridData, setGridData, handleChange } = useGrid("imageMat");
  const [sapCodes, setSapCodes] = useState([]);
  const { sapCodes: initSapCodes } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { showError } = useToast();
  const {
    state,
    handleChange: handleFormChange,
    handleStateChange,
  } = useForm({});
  const { sapCodeId, totalPair, mrpValue, isUpdated } = state;

  useEffect(() => {
    dispatch(getSapCodes({ userId: selectedUser }));
  }, [selectedUser]);

  const saveToCart = () => {
    dispatch(
      addToCart({
        userId: selectedUser,
        orderDetails: articles
          .filter((c) => c.selected)
          .map((c) => ({ imageMat: c.imageMat, orderSizes: c.orderSizes })),
      })
    );
  };

  useEffect(() => {
    if (articles && isUpdated) {
      saveToCart();
    }
  }, [articles, isUpdated]);

  useEffect(() => {
    if (initSapCodes) {
      setSapCodes(
        initSapCodes
          .filter((c) => c.isApproved)
          .map((c) => ({
            text: c.sapCode,
            value: c.sapCodeId,
          }))
      );
    }
  }, [initSapCodes]);

  useEffect(() => {
    if (sapCodes && sapCodes.length > 0) {
      handleFormChange("sapCodeId", sapCodes[0].value);
    }
  }, [sapCodes]);

  useEffect(() => {
    if (articles) {
      setGridData(
        articles
          .filter((c) => c.selected)
          .map((c, index) => ({
            ...c,
            rowNo: index + 1,
            orderSizes:
              c.orderSizes && c.orderSizes.length > 0
                ? c.orderSizes
                : orderBy(
                    uniqBy(c.sizeList, (c) => c.size),
                    [
                      (c) => (c.unit == AppConstant.PAA_UNIT ? 0 : 1),
                      (c) => parseInt(c.size),
                    ]
                  ),
          }))
      );
    }
  }, [articles]);

  useEffect(() => {
    const sizeList = gridData
      .map((c) => c.orderSizes)
      .flat(1)
      .filter((c) => c.quantity);
    handleStateChange({
      totalPair: sumBy(sizeList, (c) => c.quantity * c.packing),
      mrpValue: sumBy(sizeList, (c) => c.quantity * c.packing * c.mrp),
    });
  }, [gridData]);

  const handleSelect = (rowId, name, value) => {
    if (updateCart) {
      updateCart(rowId, { [name]: value });
      handleStateChange({ isUpdated: true });
    }
    if (name == "selected") {
      dispatch(removeFromCart({ imageMat: rowId, userId: selectedUser }));
      handleStateChange({ isUpdated: false });
    }
  };

  const rows = useCallback(() => {
    return gridData.map((c) => ({
      ...c,
      articleName: (
        <>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
              {c.articleName}
            </div>
            <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">{c.lq}</div>
            <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">{c.color}</div>
          </div>
          <div className="row">
            <div className="col-12">
              {AppConstant.CURRENCY} {c.mrp} (* Subject to revision)
            </div>
          </div>
        </>
      ),
      select: (
        <Checkbox
          name="selected"
          value={c.selected}
          handleChange={handleSelect}
          rowId={c.imageMat}
        />
      ),
      sizes: c.orderSizes.map((o) => (
        <div className="row" key={`${c.imageMat}S${o.size}`}>
          <div className="col-5 text-center">{o.cwhStock}</div>
          <div className="col-3 text-center">{o.size}</div>
          <div className="col-4">
            <Input
              name="quantity"
              type="number"
              value={o.quantity}
              placeholder="Quantity"
              handleChange={handleSelect}
              rowId={c.imageMat}
              sizeList={c.orderSizes}
              size={o.size}
            />
          </div>
        </div>
      )),
    }));
  }, [gridData, sapCodes]);

  const handleSubmit = () => {
    if (!gridData.some((s) => s.selected)) {
      showError("Please select atleast one article");
      return;
    }
    if (
      gridData.some((s) => s.selected && !s.orderSizes.some((o) => o.quantity))
    ) {
      showError("Quantity required for selected articles");
      return;
    }
    if (!sapCodeId) {
      showError("Please select sapCode");
      return;
    }
    dispatch(
      createOrder({
        orderDetails: gridData.map((c) => ({
          imageMat: c.imageMat,
          orderSizes: c.orderSizes.filter((o) => o.quantity),
        })),
        userId: selectedUser,
        sapCodeId,
        sapCode: sapCodes.find((s) => s.value == sapCodeId)?.text,
      })
    );
  };

  return (
    <>
      <div className="rightBtn">
        <UserButton
          text="Back"
          className="btn btn-primary btn-sm mr-l-10"
          onClick={handleBack}
        />
      </div>
      <Container title="Order Summary">
        <div className="row">
          <div className="col-4">
            <UserSelect
              name="sapCodeId"
              value={sapCodeId}
              placeholder="Select Sap Code"
              onChange={handleFormChange}
              options={sapCodes}
            />
          </div>
          <div className="col-8">
            <UserButton
              text="Submit Order"
              className="btn btn-primary btn-sm fr"
              onClick={handleSubmit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="fr txtFontSize" style={{ display: "flex" }}>
              <b>Total pair:</b>&nbsp;{totalPair}&nbsp;&nbsp;&nbsp;
              <b>Mrp value:</b>&nbsp;{AppConstant.CURRENCY}
              {mrpValue} (* Subject to revision)
            </div>
          </div>
        </div>
        <div className=" summaryTbl">
          <UserTable headers={meta} data={rows()} />
        </div>
      </Container>
    </>
  );
};
