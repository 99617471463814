import { Job } from "../actionTypes/job";

export const getJobList = (payload) => ({
  type: Job.GET_JOB_LIST,
  payload,
});

export const triggerJob = (payload) => ({
  type: Job.TRIGGER_JOB,
  payload,
});
