import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { deleteManager, getManagers } from "../../actions/user";
import useToggle from "../../hooks/useToggle";
import AddManagerScreen from "./AddManagerScreen";
import { DeleteModal } from "./Component";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import { includes } from "lodash";

const meta = [
  {
    key: "fullName",
    text: "Name",
    sort: true,
  },
  {
    key: "email",
    text: "Email Id",
    sort: true,
  },
  {
    key: "role",
    text: "Role",
    sort: true,
  },
  {
    key: "mobileNo",
    text: "Mobile No",
    sort: true,
  },
  {
    key: "manager",
    text: "Manager",
    sort: true,
  },
  {
    key: "action",
    text: "Action",
  },
];

const Actions = ({ index, handleEdit, handleRemove, isInActive }) => {
  const onEdit = () => {
    handleEdit(index);
  };

  const onRemove = () => {
    handleRemove(index);
  };

  return (
    <>
      {isInActive ? (
        <UserButton
          className="mr-10 link text-danger"
          text="Activate"
          onClick={onRemove}
        />
      ) : (
        <>
          <UserButton className="link" text="Edit" onClick={onEdit} />
          <UserButton
            className="mr-10 link text-danger"
            text="Deactivate"
            onClick={onRemove}
          />
        </>
      )}
    </>
  );
};

export default (props) => {
  const { show, toggle, setShow } = useToggle();
  const { show: showRemove, toggle: toggleRemove } = useToggle();
  const [managers, setManagers] = useState([]);
  const [selectedUser, setUser] = useState(null);
  const dispatch = useDispatch();
  const { state, handleChange } = useForm({});

  const { searchBy } = state;

  useEffect(() => {
    dispatch(getManagers());
  }, []);

  const { managers: initManagers } = useSelector((state) => state.user);

  const handleAdd = () => {
    setUser(null);
    setShow(true);
  };

  const handleEdit = (index) => {
    setUser(managers[index]);
    setShow(true);
  };

  const handleRemove = (index) => {
    setUser(managers[index]);
    toggleRemove();
  };

  useEffect(() => {
    if (initManagers) {
      let users = initManagers.slice();
      if (searchBy) {
        const searchByLower = searchBy?.toLowerCase();
        users = users.filter((c) =>
          meta.some((s) => includes(c[s.key]?.toLowerCase(), searchByLower))
        );
      }
      setManagers(users);
    }
  }, [initManagers, state]);

  const rows = useCallback(() => {
    return managers.map((c, index) => {
      return {
        ...c,
        action: (
          <Actions
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            index={index}
            isInActive={c.isInActive}
          />
        ),
      };
    });
  }, [managers]);

  return (
    <Container>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <TextInput
            name="searchBy"
            id="searchBy"
            value={searchBy}
            placeholder="Search by"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <UserButton
            className="btn btn-sm btn-primary fr"
            text="Add Manager/RSM_ASM"
            onClick={handleAdd}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 userTbl">
          <UserTable headers={meta} data={rows()} />
        </div>
      </div>
      {show && (
        <AddManagerScreen
          show={show}
          toggle={toggle}
          selectedUser={selectedUser}
        />
      )}
      {showRemove && (
        <DeleteModal
          show={showRemove}
          toggle={toggleRemove}
          selectedUser={selectedUser}
          action={deleteManager}
        />
      )}
    </Container>
  );
};
