import APIUtil from "./APIUtil";

class UserAPI {
  addManagerView(payload) {
    return APIUtil.callPostAPI("api/User/AddManagerView", payload);
  }

  addUserView(payload) {
    return APIUtil.callPostAPI("api/User/AddUserView", payload);
  }

  addEditManager(payload) {
    return APIUtil.callPostAPI("api/User/AddEditManager", payload);
  }

  addEditUser(payload) {
    return APIUtil.callPostAPI("api/User/AddEditUser", payload);
  }

  generatePassword(payload) {
    return APIUtil.callPostAPI("api/User/GeneratePassword", payload);
  }

  getManagers(payload) {
    return APIUtil.callPostAPI("api/User/GetManagers", payload);
  }

  getUsers(payload) {
    return APIUtil.callPostAPI("api/User/GetUsers", payload);
  }

  deleteManager(payload) {
    return APIUtil.callPostAPI("api/User/DeleteManager", payload);
  }

  deleteUser(payload) {
    return APIUtil.callPostAPI("api/User/DeleteUser", payload);
  }

  addSapCodes(payload) {
    return APIUtil.callPostAPI("api/User/AddSapCodes", payload);
  }

  getSapCodes(payload) {
    return APIUtil.callPostAPI("api/User/GetUserSapCodes", payload);
  }

  enquiryView(payload) {
    return APIUtil.callPostAPI("api/User/EnquiryView", payload);
  }

  addEditEnquiryUser(payload) {
    return APIUtil.callPostAPI("api/User/AddEditEnquiryUser", payload);
  }

  getFieldStaff(payload) {
    return APIUtil.callPostAPI("api/User/GetFieldStaff", payload);
  }

  addFieldStaffView(payload) {
    return APIUtil.callPostAPI("api/User/AddFieldStaffView", payload);
  }

  addEditFieldStaff(payload) {
    return APIUtil.callPostAPI("api/User/AddEditFieldStaff", payload);
  }

  deleteFieldStaff(payload) {
    return APIUtil.callPostAPI("api/User/DeleteFieldStaff", payload);
  }

  getDealers(payload) {
    return APIUtil.callPostAPI("api/User/GetDealers", payload);
  }

  addDealerView(payload) {
    return APIUtil.callPostAPI("api/User/AddDealerView", payload);
  }

  addEditDealer(payload) {
    return APIUtil.callPostAPI("api/User/AddEditDealer", payload);
  }

  deleteDealer(payload) {
    return APIUtil.callPostAPI("api/User/DeleteDealer", payload);
  }

  getTimesheets(payload) {
    return APIUtil.callPostAPI("api/User/GetTimesheets", payload);
  }
}

export default new UserAPI();
