import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListContainer } from "../../components/Container";
import { ArticleCard, ArticleDetail } from "./Components";
import { getArticles, setFilteredArticles } from "../../actions/article";
import "./_article.scss";
import useToggle from "../../hooks/useToggle";
import SummaryScreen from "./SummaryScreen";
import { UserButton, UserToggleButton } from "../../components/Button";
import useGrid from "../../hooks/useGrid";
import useUserDetail from "../../hooks/useUserDetail";
import { UserSelect } from "../../components/SelectBox";
import { VirtualTable } from "../../components/Table";
import ArticleImage from "./Components/ArticleImage";
import { ExportExcel, ExportHtml, ExportPDF } from "../../components/Document";
import useExcel from "../../hooks/useExcel";
import moment from "moment";
import { formatText } from "../../libs/functions";
import ScrollToTop from "../../common/ScrollToTop";
import { groupBy, includes, map, orderBy } from "lodash";
import { ArticleFields } from "../../utils/articleFields";
import useScreenSize from "../../hooks/useScreenSize";
import { TextInput, UploadFile } from "../../components/TextInput";
import { getCartDetails } from "../../actions/order";
import useForm from "../../hooks/useForm";
import { AppConstant } from "../../utils/constant";
import useToast from "../../hooks/useToast";

const viewOptions = [
  {
    text: "Card View",
    value: "card",
  },
  { text: "Grid View", value: "grid" },
];

const rowId = "imageMat",
  columnCount = 4;

const Article = ({ articles, style, rowIndex, columnIndex, ...rest }) => {
  const article = articles[rowIndex * columnCount + columnIndex];

  if (article)
    return (
      <div style={{ ...style, marginBottom: 15 }}>
        <ArticleCard articleDetails={article} {...rest} />
      </div>
    );

  return <></>;
};

const disableIndicators = ["B", "F", "T"];

export default (props) => {
  const dispatch = useDispatch();
  const { gridData: articles, setGridData, handleRowClick } = useGrid(rowId);
  const { show: showSummary, toggle: toggleSummary } = useToggle();
  const [headers, setHeaders] = useState([]);
  const { initArticles, initUsers, filteredArticles, initCartDetails } =
    useSelector((state) => {
      return {
        initArticles: state.article.articles,
        initUsers: state.article.users,
        filteredArticles: state.article.filteredArticles,
        initCartDetails: state.order?.cartDetails,
      };
    });
  const [selectedUser, setUser] = useState(null);
  const {
    isUser,
    isCoordinator,
    isEnquiryUser,
    isFieldStaff,
    isDistributor,
    email,
    isHeadChannelUser,
  } = useUserDetail();
  const [width, setWidth] = useState(1000);
  const [viewOption, setViewOption] = useState(viewOptions[0].value);
  const divElement = useRef();
  const gridRef = useRef();
  const { excelDataSet } = useExcel(articles, headers);
  const [totalSelected, setSelected] = useState(0);
  const [cartDetails, setCartDetails] = useState([]);
  const { screenWidth } = useScreenSize();
  const { show: showDetail, toggle: toggleDetail } = useToggle();
  const [selectedArticle, setSelectedArticle] = useState(null);
  const {
    state: { materialCode, viewArticleOnly },
    handleChange,
  } = useForm({});
  const inputRef = useRef();
  const { showError } = useToast();

  useEffect(() => {
    if (!initArticles) dispatch(getArticles({}));
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (selectedUser && initUsers) {
      dispatch(getCartDetails(selectedUser));
      handleChange(
        "viewArticleOnly",
        initUsers.find((c) => c.value == selectedUser)?.viewArticleOnly
      );
    }
  }, [selectedUser, initUsers]);

  useEffect(() => {
    if (initArticles && initArticles.length > 0) {
      const keys = Object.keys(initArticles[0]);
      setHeaders([
        { key: "image", text: "" },
        ...ArticleFields.filter((c) => includes(keys, c)).map((key) => ({
          key,
          text: formatText(key),
        })),
      ]);
    }
  }, [initArticles]);

  useEffect(() => {
    if (initCartDetails) {
      setCartDetails(
        initCartDetails.map((c) => ({
          ...c,
          selected: true,
          orderSizes: c.orderSizes?.map((s) => ({ ...s, selected: true })),
        }))
      );
    }
  }, [initCartDetails]);

  const setArticles = (articles) => {
    if (selectedUser) {
      const user = initUsers.find((c) => c.value == selectedUser);
      const { brands, channels, indicators } = user ? user : {};
      if (brands && brands.length > 0) {
        articles = articles.filter((c) => includes(brands, c.brand));
      }
      if (channels && channels.length > 0) {
        articles = articles.filter((c) => includes(channels, c.ownership));
      }
      if (indicators && indicators.length > 0) {
        articles = articles.filter((c) => includes(indicators, c.ind));
      }
    }
    if (selectedUser && (isCoordinator || isFieldStaff) && initUsers) {
      const userType = initUsers.find((c) => c.value == selectedUser)?.userType;
      articles = articles.filter((c) => c.userTypes?.includes(userType));
    }
    setGridData(
      orderBy(
        articles.map((a) => ({
          ...a,
          orderSizes: null,
          selected: false,
          ...cartDetails?.find((c) => c.imageMat == a.imageMat),
        })),
        [(c) => c.articleName, (c) => c.imageMat],
        ["asc", "asc"]
      )
    );
  };

  const updateArticles = (articles) => {
    dispatch(setFilteredArticles({ articles }));
  };

  useEffect(() => {
    const width = divElement.current?.clientWidth;
    setWidth(width < 920 ? 1100 : width);
  }, [screenWidth]);

  const handleFile = (name, excelData) => {
    if (initArticles && excelData) {
      // const uploadedArticles = map(
      //   groupBy(excelData, (c) => c["Common Mat"]),
      //   (c, key) => ({
      //     sizes: c.map((s) => s["SIZE"]?.toString()),
      //     imageMat: key,
      //   })
      // );
      if (excelData.length > 0) {
        const filteredArticles = initArticles.filter((art) =>
          excelData.some(
            (s) =>
              s[0] == art.articleName &&
              (!s[1] || s[1].toLowerCase() == art.color?.toLowerCase())
          )
        );
        updateArticles(filteredArticles);
      }
    }
  };

  useEffect(() => {
    setSelected(articles.filter((c) => c.selected).length);
  }, [articles]);

  useEffect(() => {
    if (
      initUsers &&
      initUsers.length > 0 &&
      (isUser || isCoordinator || isFieldStaff)
    ) {
      setUser(initUsers[0].value);
    }
  }, [initUsers]);

  useEffect(() => {
    if (filteredArticles && !showDetail) {
      setArticles(filteredArticles);
    }
  }, [initUsers, selectedUser, filteredArticles, cartDetails, showDetail]);

  useEffect(() => {
    if (!showDetail) {
      handleChange("materialCode", null);
    }
  }, [showDetail]);

  const handleMaterialCode = (event) => {
    event.preventDefault();
    const selectedArticle = filteredArticles.find(
      (c) =>
        c.sizeList &&
        c.sizeList.some(
          (s) => s.material == materialCode || s.eanNo == materialCode
        )
    );
    if (materialCode && selectedArticle) {
      setArticles([selectedArticle]);
      handleViewClick(selectedArticle);
    } else {
      setArticles(filteredArticles);
    }
  };

  const handleUserChange = (name, value) => {
    setUser(value);
  };

  const handleViewChange = (name, value) => {
    setViewOption(value);
  };

  const rows = useCallback(() => {
    if (articles) {
      return articles.map((c) => ({
        ...c,
        image: (
          <ArticleImage
            imagePath={c.imagePath}
            style={{ width: 90, height: 90 }}
          />
        ),
      }));
    }
  }, [articles]);

  const addEditCartDetails = (imageMat, payload) => {
    let cartItems = cartDetails.slice();
    const index = cartItems.findIndex((p) => p.imageMat == imageMat);
    if (index > -1) cartItems[index] = { ...cartItems[index], ...payload };
    else cartItems.push({ imageMat, ...payload });

    setCartDetails(cartItems);
  };

  const addToCart = ({ orderSizes, imageMat }) => {
    addEditCartDetails(imageMat, { orderSizes, selected: true });
    toggleDetail();
  };

  const selectArticles = (imageMat, name, value) => {
    addEditCartDetails(imageMat, { [name]: value });
  };

  const selectedArticles = useMemo(() => {
    return {
      imageMatList: articles.some((c) => c.selected)
        ? articles.filter((c) => c.selected).map((c) => c.imageMat)
        : articles.map((c) => c.imageMat),
    };
  }, [articles]);

  const cartArticles = useMemo(() => {
    if (cartDetails && initArticles)
      return cartDetails.map((c) => ({
        ...initArticles.find((a) => a.imageMat == c.imageMat),
        ...c,
      }));

    return [];
  }, [initArticles, cartDetails]);

  const toggleSelected = () => {
    setCartDetails([]);
  };

  const handleViewClick = (selectedArticle) => {
    setSelectedArticle(selectedArticle);
    toggleDetail();
  };

  const handleCreateOrder = () => {
    if (
      articles.some((c) => c.selected && disableIndicators.includes(c.ind)) &&
      !isHeadChannelUser
    ) {
      showError(
        `Order not allowed for these indicators ${disableIndicators.join(",")}`
      );
      return;
    }
    toggleSummary();
  };

  const isSelected = useMemo(
    () => articles.some((c) => c.selected),
    [articles]
  );

  if (showSummary) {
    return (
      <SummaryScreen
        articles={cartArticles}
        selectedUser={selectedUser}
        handleBack={toggleSummary}
        updateCart={addEditCartDetails}
      />
    );
  }

  return (
    <div className="articleContainer">
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
          <div className="row">
            {(isCoordinator || isFieldStaff || isDistributor) && (
              <div className="col-6">
                <UserSelect
                  name="selectedUser"
                  value={selectedUser}
                  options={initUsers}
                  onChange={handleUserChange}
                  placeholder="Select Customer"
                />
              </div>
            )}
            {selectedUser && (
              <div className="col-6">
                <UserButton
                  text="Create Order"
                  className="btn btn-primary btn-sm   mr-l-10"
                  disabled={!isSelected}
                  onClick={handleCreateOrder}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 btnWrapper">
          <div className="row">
            {!isUser && !isEnquiryUser && !isFieldStaff && (
              <>
                <span>
                  <ExportExcel
                    dataSet={excelDataSet}
                    filename={`Articles_${moment().format(
                      "DDMMYYYYHHmm"
                    )}.xlsx`}
                    sheetName="Articles"
                    payload={selectedArticles}
                  />
                </span>
                <span>
                  <ExportExcel
                    dataSet={excelDataSet}
                    filename={`Articles_${moment().format(
                      "DDMMYYYYHHmm"
                    )}.xlsx`}
                    sheetName="Articles"
                    payload={selectedArticles}
                    fileType="CATEXCEL"
                    text="Download Product Excel"
                  />
                </span>
                <span>
                  <ExportPDF
                    filename={`Articles_${moment().format("DDMMYYYYHHmm")}.pdf`}
                    payload={selectedArticles}
                  />
                </span>
                <span>
                  <ExportHtml
                    filename={`Articles_${moment().format(
                      "DDMMYYYYHHmm"
                    )}.html`}
                    payload={selectedArticles}
                  />
                </span>
              </>
            )}
            <span>
              <UserToggleButton
                options={viewOptions}
                onChange={handleViewChange}
                value={viewOption}
              />
            </span>
            <div>
              <UploadFile onChange={handleFile} isExcel />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-3 col-xs-3">
          <form onSubmit={handleMaterialCode}>
            <TextInput
              name="materialCode"
              value={materialCode}
              type="text"
              placeholder="Enter material or EAN code"
              onChange={handleChange}
              inputRef={inputRef}
            />
          </form>
        </div>
        <div className="col-md-7 col-sm-6 col-xs-6 cursor txtFontSize fr">
          {isSelected && (
            <UserButton
              text="Unselect All"
              className="btn btn-primary btn-sm"
              onClick={toggleSelected}
            />
          )}
          <b className="mr-l-10">
            Article Count: {articles ? articles.length : 0}
          </b>{" "}
          <b onClick={toggleSelected} title="Unselect All" className="mr-l-10">
            Selected article Count: {totalSelected}
          </b>{" "}
        </div>
        <div className="col-md-2 col-sm-1 col-xs-1">
          <ScrollToTop targetRef={gridRef} />
        </div>
      </div>
      <div className="listWrapper" ref={divElement}>
        {articles && viewOption == "card" && (
          <ListContainer
            rowHeight={440}
            columnCount={columnCount}
            rowCount={Math.ceil(articles.length / columnCount)}
            columnWidth={width / columnCount - 7}
            gridRef={gridRef}
          >
            {({ columnIndex, rowIndex, style }) => (
              <Article
                columnIndex={columnIndex}
                rowIndex={rowIndex}
                style={style}
                articles={articles}
                handleChange={selectArticles}
                showUpload={email == AppConstant.IMAGE_UPLOAD_DELETE_EMAIL}
                showCart={
                  !viewArticleOnly && (isUser || isCoordinator || isFieldStaff)
                }
                addToCart={addToCart}
                handleViewClick={handleViewClick}
              />
            )}
          </ListContainer>
        )}
        {articles && viewOption == "grid" && (
          <VirtualTable
            headers={headers}
            data={rows()}
            onRowClick={handleRowClick}
            rowId={rowId}
          />
        )}
      </div>
      {showDetail && (
        <ArticleDetail
          showDetail={showDetail}
          toggleDetail={toggleDetail}
          articleDetails={selectedArticle}
          showCart={
            !viewArticleOnly &&
            (isUser || isCoordinator || isFieldStaff) &&
            (!disableIndicators.includes(selectedArticle?.ind) ||
              isHeadChannelUser)
          }
          addToCart={addToCart}
          showUpload={email == AppConstant.IMAGE_UPLOAD_DELETE_EMAIL}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
};
