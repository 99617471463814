import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAppConstant, getAppConstant } from "../../actions/setting";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import useScreenSize from "../../hooks/useScreenSize";
import SeasonSetting from "./SeasonSetting";

const name = "MaxCWHStock";

const meta = [
  { key: "sNo", text: "S.No" },
  { key: "user", text: "User" },
];

export default (props) => {
  const { state, handleChange, errors, handleSubmit } = useForm(
    {},
    { maxCWHStock: { required: true } },
    submit
  );
  const dispatch = useDispatch();
  const constant = useSelector((state) => state.setting?.constant?.text);
  const activeUsers = useSelector((state) => state.setting?.activeUsers);
  const { isMobileView } = useScreenSize();

  useEffect(() => {
    if (constant) handleChange("maxCWHStock", constant);
  }, [constant]);

  useEffect(() => {
    dispatch(getAppConstant(name));
  }, []);

  const rows = useCallback(() => {
    if (activeUsers) {
      return activeUsers.map((c, index) => ({
        user: c.text,
        sNo: index + 1,
      }));
    }

    return [];
  }, [activeUsers]);

  function submit(payload) {
    dispatch(addAppConstant({ value: name, text: payload.maxCWHStock }));
  }

  const { maxCWHStock } = state;

  return (
    <Container title="Setting">
      <form onSubmit={handleSubmit} method="post">
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-lg-4 col-md-6 col-xs-6 col-sm-6">
            <TextInput
              name="maxCWHStock"
              id="maxCWHStock"
              value={maxCWHStock}
              placeholder="Max CWH Stock"
              label="Max CWH Stock"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6" style={{ marginTop: isMobileView ? 10 : 30 }}>
            <UserButton
              text="Save"
              className="btn btn-primary btn-sm"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
      <SeasonSetting />
      <div className="row">
        <div className="col-12 activeUserTbl">
          <h4>Active Users ({activeUsers?.length})</h4>
          <UserTable data={rows()} headers={meta} />
        </div>
      </div>
    </Container>
  );
};
