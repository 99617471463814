import { includes, maxBy, minBy, orderBy, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import useToggle from "../../hooks/useToggle";
import { MultiSelect } from "../../components/SelectBox";
import { setFilteredArticles } from "../../actions/article";
import { RiFilterOffLine } from "react-icons/ri";
import useForm from "../../hooks/useForm";
import { useLocation } from "react-router";
import { RouteConstant } from "../../utils/routeConstant";
import useUserDetail from "../../hooks/useUserDetail";
import UserSlider from "../../components/Slider/UserSlider";

const imageOptions = [
  { text: "With Image", value: true },
  { text: "W/O Image", value: false },
];

export default (props) => {
  const { articles: allArticles } = useSelector((state) => state.article);
  const { pathname } = useLocation();
  const [filters, setFilters] = useState([]);
  const { show, toggle, setShow } = useToggle(false);
  const { show: showReset, setShow: setReset } = useToggle(false);
  const dispatch = useDispatch();
  const { isAdmin, isManager } = useUserDetail();
  const roleFilters = useSelector((state) => state.account?.filters);
  const { state, handleChange, handleStateChange } = useForm({
    filterBy: {
      // customField1: [{ text: "1", value: "1" }],
    },
    mrpValues: [],
    // currentFilter: "customField1",
  });
  const { filterBy, currentFilter, mrpValues, minMrp, maxMrp } = state;

  useEffect(() => {
    setShow(pathname == RouteConstant.ARTICLES);
  }, [pathname]);

  useEffect(() => {
    if (filterBy) setReset(true);
    else setReset(false);
  }, [filterBy]);

  const mapList = (key, articles) =>
    orderBy(
      uniqBy(
        (key == "size"
          ? articles.map((c) => c.sizeList).flat(1)
          : articles
        ).filter((c) => c[key]),
        (c) => c[key]
      ).map((c) => ({
        text: c[key],
        value: c[key],
      })),
      (c) => c.text
    );

  const setFilterValues = (roleFilters, articles) => {
    setFilters((prev) => {
      const option = prev.find((c) => c.name == currentFilter);
      return orderBy(
        uniqBy(
          [
            ...(option && option.options && option.options.length > 0
              ? [option]
              : []),
            ...roleFilters.map((c) => ({
              options: mapList(c.value, articles),
              name: c.value,
              label: c.text,
              sortOrder: c.sortOrder,
            })),
            ...(isAdmin || isManager
              ? [{ options: imageOptions, name: "hasImage", label: "Image" }]
              : []),
          ],
          (c) => c.name
        ),
        (c) => c.sortOrder
      );
    });
  };

  useEffect(() => {
    if (allArticles && roleFilters) {
      setFilterValues(roleFilters, allArticles);
    } else if (roleFilters) {
      setFilters(
        orderBy(
          roleFilters.map((c) => ({
            options: [],
            name: c.value,
            label: c.text,
            sortOrder: c.sortOrder,
          })),
          (c) => c.sortOrder
        )
      );
    }
  }, [allArticles, roleFilters, showReset]);

  const handleReset = () => {
    dispatch(setFilteredArticles({ filteredArticles: null }));
    handleStateChange({
      filterBy: null,
      currentFilter: null,
      mrpValues: [minMrp, maxMrp],
    });
  };

  useEffect(() => {
    if (allArticles) {
      let filteredArticles = allArticles.slice();
      if (filterBy) {
        Object.keys(filterBy).forEach((key) => {
          const values = filterBy[key]?.map((c) => c.value);
          if (values && values.length > 0) {
            if (key == "size")
              filteredArticles = filteredArticles.filter((c) =>
                c.sizeList.some((s) => includes(values, s[key]))
              );
            else if (key == "hasImage") {
              if (values.length == 1) {
                if (values[0])
                  filteredArticles = filteredArticles.filter(
                    (c) => c.imagePath
                  );
                else
                  filteredArticles = filteredArticles.filter(
                    (c) => !c.imagePath
                  );
              }
            } else
              filteredArticles = filteredArticles.filter((c) =>
                includes(values, c[key])
              );
          }
        });
      }
      if (mrpValues && mrpValues.length > 0) {
        filteredArticles = filteredArticles.filter(
          (c) => c.mrp >= mrpValues[0] && c.mrp <= mrpValues[1]
        );
      }
      setFilterValues(
        roleFilters.filter((c) => c.value != currentFilter),
        filteredArticles
      );
      dispatch(setFilteredArticles({ filteredArticles }));
    }
  }, [allArticles, filterBy, currentFilter, mrpValues]);

  useEffect(() => {
    if (allArticles) {
      const minMrp = minBy(allArticles, (c) => c.mrp)?.mrp,
        maxMrp = maxBy(allArticles, (c) => c.mrp)?.mrp;
      handleStateChange({
        minMrp,
        maxMrp,
        mrpValues: [minMrp, maxMrp],
      });
    }
  }, [allArticles]);

  const handleFilterChange = (name, values) => {
    handleStateChange({
      filterBy: { ...filterBy, [name]: values },
      currentFilter: name,
    });
  };

  return (
    <>
      <span className="fr filterToggleIc" onClick={toggle}>
        {showReset && (
          <span className="mr-l-10" onClick={handleReset}>
            <RiFilterOffLine color="white" fontSize={16} />
          </span>
        )}
        <span>
          {show ? (
            <MdExpandLess color="white" fontSize={20} />
          ) : (
            <MdExpandMore color="white" fontSize={20} />
          )}
        </span>
      </span>
      {show && (
        <div className=" filters">
          {filters.map((c) => (
            <div key={c.name} style={{ marginTop: 10 }}>
              <MultiSelect
                name={c.name}
                values={filterBy ? filterBy[c.name] : null}
                options={c.options}
                onChange={handleFilterChange}
                placeholder={`${c.label}`}
              />
            </div>
          ))}
          {minMrp && maxMrp && (
            <div style={{ marginTop: 10 }}>
              <UserSlider
                min={minMrp}
                max={maxMrp}
                name="mrpValues"
                value={mrpValues}
                step={100}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
