import React, { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import ReactDOM from "react-dom";

export default ({ targetRef }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (targetRef.current?.scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    targetRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    ReactDOM.findDOMNode(targetRef.current)?.addEventListener(
      "scroll",
      toggleVisibility
    );
    return () =>
      ReactDOM.findDOMNode(targetRef.current)?.removeEventListener(
        "scroll",
        toggleVisibility
      );
  }, [targetRef.current]);

  return (
    <div className="scroll-to-top" style={{ marginLeft: 20 }}>
      {isVisible && (
        <div onClick={scrollToTop} title="Go to top">
          <FaArrowCircleUp fontSize={16} />
        </div>
      )}
    </div>
  );
};
