import React from "react";

export default ({
  labelClassName,
  tip,
  name,
  wrapperStyle,
  asterix,
  label,
}) => {
  return (
    <span style={{ ...wrapperStyle }}>
      <label
        className={labelClassName ? labelClassName : "control-label"}
        htmlFor={name}
      >
        {label}
        {asterix ? <span className="asterix">*</span> : ""}
      </label>
      {tip ? <div className="tip">{tip}</div> : ""}
    </span>
  );
};
