import React, { useCallback } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { RouteConstant } from "../utils/routeConstant.js";
import auth from "../libs/auth.js";
import {
  ConfirmAccountScreen,
  ForgotPasswordScreen,
  LoginScreen,
  ResetPasswordScreen,
} from "../containers/Login";
import {
  ManagerScreen,
  UserScreen,
  FieldStaffScreen,
  DealerScreen,
} from "../containers/User";
import { ArticleScreen, CartScreen } from "../containers/Article";
import { OrderScreen } from "../containers/Order";
import { SettingScreen } from "../containers/Setting";
import { JobScreen } from "../containers/Jobs";
import useUserDetail from "../hooks/useUserDetail";
import { RoleConstant } from "../utils/roleConstant";
import {
  CheckInDetailScreen,
  OfficeStaffTimesheet,
} from "../containers/FieldStaff";
import { UserListScreen } from "../containers/Impersonation";

const {
  LOGIN_SCREEN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CONFIRM_ACCOUNT,
  MANAGERS,
  USERS,
  ORDERS,
  ARTICLES,
  SETTING,
  JOBS,
  CART,
  DEALER,
  ENQUIRY,
  REGISTER_USER,
  FIELD_STAFF,
  FIELD_STAFF_TIME_TABLE,
  OFFICE_STAFF_TIME_TABLE,
  IMPERSONATION,
} = RouteConstant;

const Routes = (props) => {
  const { pathname } = useLocation();
  const { role, showDealers } = useUserDetail();
  const PrivateRoute = useCallback(
    ({ component: Component, path, authorized, ...rest }) => {
      if (!role) return <></>;
      else if (!authorized || authorized.includes(role))
        return (
          <Route
            {...rest}
            render={(props) => {
              if (auth.isAuthenticated) return <Component {...props} />;
              else {
                return <Redirect to={LOGIN_SCREEN} />;
              }
            }}
          />
        );
      else return <Redirect to={LOGIN_SCREEN} />;
    },
    [pathname, role]
  );

  return (
    <Switch>
      <PrivateRoute
        exact
        path={JOBS}
        component={JobScreen}
        authorized={[RoleConstant.ADMIN]}
      />
      <PrivateRoute
        exact
        path={IMPERSONATION}
        component={UserListScreen}
        authorized={[RoleConstant.ADMIN]}
      />
      <PrivateRoute
        exact
        path={MANAGERS}
        component={ManagerScreen}
        authorized={[RoleConstant.ADMIN]}
      />
      <PrivateRoute
        exact
        path={SETTING}
        component={SettingScreen}
        authorized={[RoleConstant.ADMIN]}
      />
      <PrivateRoute exact path={ORDERS} component={OrderScreen} />
      <PrivateRoute exact path={ARTICLES} component={ArticleScreen} />
      <PrivateRoute exact path={CART} component={CartScreen} />
      <PrivateRoute
        exact
        path={USERS}
        component={UserScreen}
        authorized={[RoleConstant.ADMIN, RoleConstant.SALES_COORDINATOR]}
      />
      <PrivateRoute
        exact
        path={FIELD_STAFF}
        component={FieldStaffScreen}
        authorized={[
          RoleConstant.ADMIN,
          RoleConstant.USER,
          RoleConstant.SALES_COORDINATOR,
          RoleConstant.MANAGER,
        ]}
      />
      {showDealers && (
        <PrivateRoute
          exact
          path={DEALER}
          component={DealerScreen}
          authorized={[
            RoleConstant.ADMIN,
            RoleConstant.USER,
            RoleConstant.SALES_COORDINATOR,
            RoleConstant.FIELD_STAFF,
            RoleConstant.MANAGER,
          ]}
        />
      )}
      <PrivateRoute
        exact
        path={FIELD_STAFF_TIME_TABLE}
        component={CheckInDetailScreen}
        authorized={[
          RoleConstant.ADMIN,
          RoleConstant.USER,
          RoleConstant.SALES_COORDINATOR,
          RoleConstant.MANAGER,
        ]}
      />
      <PrivateRoute
        exact
        path={OFFICE_STAFF_TIME_TABLE}
        component={OfficeStaffTimesheet}
        authorized={[
          RoleConstant.ADMIN,
          RoleConstant.USER,
          RoleConstant.SALES_COORDINATOR,
          RoleConstant.MANAGER,
        ]}
      />
      {/* <Route exact path={ENQUIRY} component={EnquiryScreen} />
      <Route exact path={REGISTER_USER} component={RegisterUserScren} /> */}
      <Route
        exact
        path={`${CONFIRM_ACCOUNT}/:email/:token`}
        component={ConfirmAccountScreen}
      />
      <Route
        exact
        path={`${RESET_PASSWORD}/:email/:token`}
        component={ResetPasswordScreen}
      />
      <Route exact path={FORGOT_PASSWORD} component={ForgotPasswordScreen} />
      <Route exact path={LOGIN_SCREEN} component={LoginScreen} />
      <Route exact path="/" render={() => <Redirect to={LOGIN_SCREEN} />} />
    </Switch>
  );
};

export default React.memo(Routes);
