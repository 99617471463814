import { Order } from "../actionTypes/order";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Order.ORDER_VIEW:
      return {
        ...initialState,
      };
    case Order.ORDER_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    case Order.GET_CART_DETAILS:
      return {
        ...state,
        cartDetails: [],
      };
    case Order.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        cartDetails: [],
      };
    case Order.CART_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    default:
      return { ...state };
  }
};
