import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import progress from "./progress";
import response from "./response";
import account from "./account";
import user from "./user";
import article from "./article";
import order from "./order";
import setting from "./setting";
import job from "./job";
import registerUser from "./registerUser";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    account,
    user,
    article,
    order,
    progress,
    response,
    setting,
    job,
    registerUser,
  });
export default createRootReducer;
