import React from "react";
import PropTypes from "prop-types";
import { UserError, UserLabel } from "../Label";
import { formatInputText } from "../../libs/functions";

const Input = (props) => {
  const {
    name,
    placeholder,
    maxLength,
    toUpper,
    className,
    disabled,
    multiple,
    suffix,
    value,
    onChange,
    error,
  } = props;

  return (
    <span>
      <textarea
        id={name}
        name={`${name}`}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength ? maxLength : 200}
        value={toUpper && value ? value.toUpperCase() : value ? value : ""}
        className={`form-control input-sm ${className ? `${className}` : ""} ${
          error ? "errInput" : ""
        }`}
        autoComplete={`auto-${name}`}
        disabled={disabled}
        multiple={multiple ? true : false}
        rows={4}
        cols={12}
      />
      <span className="txtSuffix">{suffix}</span>
    </span>
  );
};

const TextArea = (props) => {
  const onFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    const { onChange, formatText: format, minValue } = props;
    if (format) value = formatInputText(value);
    if (value && minValue && value < minValue) return;
    onChange(name, value);
  };

  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div>
            <UserLabel {...props} />
            <Input {...props} onChange={onFieldChange} error={error} />
          </div>
        ) : (
          <div className="row m-0">
            <div className="col-sm-12 col-xs-12 col-lg-12 px-0">
              <Input {...props} onChange={onFieldChange} error={error} />
            </div>
          </div>
        )}
        <UserError error={error} />
      </div>
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  alhpaNumeric: PropTypes.bool,
  button: PropTypes.func,
  url: PropTypes.string,
  error: PropTypes.string,
  link: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  class: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  toUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;
