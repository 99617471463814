import { includes, omit, orderBy, uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImages,
  getArticleImages,
  updateArticle,
} from "../../../actions/article";
import { UserButton } from "../../../components/Button";
import { UserCheckbox } from "../../../components/Checkbox";
import { UserTable } from "../../../components/Table";
import { TextInput } from "../../../components/TextInput";
import useForm from "../../../hooks/useForm";
import useGrid from "../../../hooks/useGrid";
import useToast from "../../../hooks/useToast";
import { formatText } from "../../../libs/functions";
import ArticleImage from "./ArticleImage";
import { AiOutlineDelete } from "react-icons/ai";
import useToggle from "../../../hooks/useToggle";
import { UserModal } from "../../../components/Modal";
import { ArticleFields } from "../../../utils/articleFields";
import { AppConstant } from "../../../utils/constant";
import LargeImage from "./LargeImage";
import { addToCart as addToCartAction } from "../../../actions/order";
import { DisplayLabel } from "../../../components/Label";

const meta = [
  {
    key: "select",
    text: "",
  },
  {
    key: "size",
    text: "Size",
  },
  {
    key: "cwhStock",
    text: "CWH Stock",
  },
  {
    key: "quantity",
    text: "Quantity",
  },
];

const Input = ({ name, value, type, placeholder, handleChange, rowId }) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return (
    <TextInput
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      minValue={1}
    />
  );
};

const Checkbox = ({ name, value, handleChange, rowId }) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return <UserCheckbox name={name} checked={value} onChange={onChange} />;
};

const Image = ({
  imageDetail,
  index,
  selectedIndex,
  setCurrIndex,
  showUpload,
}) => {
  const { show: showConfirm, toggle: toggleConfirm } = useToggle();
  const dispatch = useDispatch();

  const handleClick = () => {
    setCurrIndex(index);
  };

  const handleDelete = () => {
    const { articleImageId } = imageDetail;
    dispatch(deleteImages(articleImageId));
    toggleConfirm();
  };

  return (
    <>
      <div onClick={handleClick}>
        <ArticleImage
          {...imageDetail}
          className={`smallImg${index === selectedIndex ? " selectedImg" : ""}`}
        />
      </div>
      {showUpload && (
        <div>
          <span>
            <AiOutlineDelete onClick={toggleConfirm} className="deleteIc" />
          </span>
        </div>
      )}
      {showConfirm && (
        <UserModal
          btnText="Delete Image"
          show={showConfirm}
          heading="Delete Image"
          onClose={toggleConfirm}
          handleClick={handleDelete}
        >
          Are you sure you want to delete image?
        </UserModal>
      )}
    </>
  );
};

const rowId = "size";

const ArticleDetail = ({
  articleDetails,
  showCart,
  addToCart,
  showUpload,
  selectedUser,
}) => {
  const { imageMat } = articleDetails ? articleDetails : {};
  const { articleImages } = useSelector((state) => state.article);
  const { state, handleStateChange } = useForm({ details: [] });
  const dispatch = useDispatch();
  const labels = useSelector((state) => state.account?.labels);
  const [currIndex, setCurrIndex] = useState(0);
  const [headers, setHeaders] = useState(meta);
  const {
    gridData: sizes,
    setGridData: setSizes,
    handleChange,
    handleRowChange,
  } = useGrid(rowId);
  const { showError } = useToast();
  const { details } = state;

  useEffect(() => {
    dispatch(getArticleImages(imageMat));
  }, []);

  useEffect(() => {
    if (!showCart) {
      setHeaders(meta.filter((c) => c.key != "select" && c.key != "quantity"));
    }
  }, [showCart]);

  useEffect(() => {
    if (articleDetails) {
      const { orderSizes, sizeList } = articleDetails;
      setSizes(
        orderBy(
          uniqBy(sizeList, (c) => c.size).map((c) => ({
            size: c.size,
            cwhStock: c.cwhStock,
            packing: c.packing,
            mrp: c.mrp,
            // selected: true,
            ...orderSizes?.find((o) => o.size == c.size),
          })),
          [
            (c) => (c.unit == AppConstant.PAA_UNIT ? 0 : 1),
            (c) => parseInt(c.size),
          ],
          ["asc", "asc"]
        )
      );
      const keys = Object.keys(articleDetails);
      handleStateChange({
        details: ArticleFields.filter((c) => includes(keys, c)).map((c) => {
          const displayLabel = labels.find((s) => s.value == c)?.text;
          return {
            label: displayLabel ? displayLabel : formatText(c),
            value: articleDetails[c],
          };
        }),
      });
    }
  }, [articleDetails]);

  const handleInputChange = (rowId, name, value) => {
    handleRowChange(rowId, { [name]: value, selected: value != "" });
  };

  const rows = useCallback(() => {
    return sizes.map((c) => ({
      ...c,
      ...(showCart
        ? {
            select: (
              <Checkbox
                name="selected"
                value={c.selected}
                handleChange={handleChange}
                rowId={c.size}
              />
            ),
            quantity: (
              <Input
                name="quantity"
                type="number"
                value={c.quantity}
                placeholder="Quantity"
                handleChange={handleInputChange}
                rowId={c.size}
                minValue={1}
              />
            ),
          }
        : null),
    }));
  }, [sizes]);

  const handleAdd = () => {
    if (!sizes.some((s) => s.selected)) {
      showError("Please select atleast one size");
      return;
    }
    if (sizes.some((s) => s.selected && !s.quantity)) {
      showError("Please add quantity of selected sizes");
      return;
    }
    addToCart({ imageMat, orderSizes: sizes.filter((c) => c.selected) });
    dispatch(
      addToCartAction({
        userId: selectedUser,
        orderDetails: [
          { imageMat, orderSizes: sizes.filter((c) => c.selected) },
        ],
      })
    );
  };

  return (
    <>
      <div className="row detailWrap">
        <div className="articleImgContainer col-lg-2 col-md-4 col-sm-4 col-xs-4 customCol2">
          {articleImages &&
            articleImages.map((a, index) => (
              <div
                key={`A${index}`}
                style={{ display: "flex", position: "relative" }}
              >
                <Image
                  imageDetail={a}
                  setCurrIndex={setCurrIndex}
                  index={index}
                  showUpload={showUpload}
                  selectedIndex={currIndex}
                />
              </div>
            ))}
        </div>
        <LargeImage
          articleImage={articleImages ? articleImages[currIndex] : null}
          key={currIndex}
          articleImages={articleImages}
          currIndex={currIndex}
          selectImage={setCurrIndex}
          // isLargeImage
        />
        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <div className="row">
            {details.map((c, index) => (
              <React.Fragment key={`Art${index}`}>
                {c.value && (
                  <div className="col-6">
                    <DisplayLabel {...c} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-9 sizeTableWrap">
                  <div className="stkText">
                    CWH Stock (* Subject to stock availability)
                  </div>
                  <UserTable headers={headers} data={rows()} rowId={rowId} />
                </div>
                {showCart && (
                  <div className="col-3">
                    <UserButton text="Add to cart" onClick={handleAdd} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default (props) => {
  const { showDetail, toggleDetail } = props;

  return (
    <UserModal
      show={showDetail}
      heading="Article Detail"
      onClose={toggleDetail}
      hideBtn
      size="xl"
    >
      <ArticleDetail {...props} />
    </UserModal>
  );
};
