import { takeLatest, call, put } from "redux-saga/effects";
import SettingAPI from "../api/SettingAPI";
import { Setting } from "../actionTypes/setting";
import callAPI from "./helper";

function* getAppConstant(action) {
  yield call(callAPI, SettingAPI.getAppConstant, action, setResult);
}

function* addAppConstant(action) {
  yield call(callAPI, SettingAPI.addAppConstant, action, setResult);
}

function* addEditUserSeasons(action) {
  yield call(callAPI, SettingAPI.addEditUserSeasons, action, setResult);
}

function* sendFeedback(action) {
  yield call(callAPI, SettingAPI.sendFeedback, action, setResult);
}

function* setResult(result) {
  yield put({ type: Setting.SETTING_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Setting.GET_APP_CONSTANT, getAppConstant);
  yield takeLatest(Setting.ADD_APP_CONSTANT, addAppConstant);
  yield takeLatest(Setting.ADD_EDIT_USER_SEASONS, addEditUserSeasons);
  yield takeLatest(Setting.SEND_FEEDBACK, sendFeedback);
}
