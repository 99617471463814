import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserError, UserLabel } from "../Label";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import useToggle from "../../hooks/useToggle";
import { formatInputText } from "../../libs/functions";
import * as XLSX from "xlsx";

const Input = (props) => {
  const {
    inputRef,
    name,
    type: initType,
    placeholder,
    maxLength,
    toUpper,
    className,
    pattern,
    disabled,
    multiple,
    suffix,
    value,
    onChange,
    error,
    minValue,
  } = props;

  const { show, toggle, setShow } = useToggle();

  const [type, setType] = useState("text");

  useEffect(() => {
    if (initType) {
      setType(show ? "text" : initType);
    }
  }, [initType, show]);

  const showPassord = () => {
    setShow(true);
  };

  const hidePassword = () => {
    setShow(false);
  };

  const onClick = (event) => {
    event.stopPropagation();
  };

  return (
    <span>
      <input
        onClick={onClick}
        ref={inputRef}
        id={name}
        type={type}
        name={`${name}`}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength ? maxLength : 200}
        value={toUpper && value ? value.toUpperCase() : value ? value : ""}
        className={`form-control input-sm ${className ? `${className}` : ""} ${
          error ? "errInput" : ""
        }`}
        autoComplete={`auto-${name}`}
        pattern={pattern ? pattern : null}
        disabled={disabled}
        multiple={multiple ? true : false}
        min={minValue}
      />
      {initType == "password" && !disabled && (
        <>
          {show ? (
            <AiFillEye
              className="passwordIc"
              onClick={toggle}
              onMouseLeave={hidePassword}
            />
          ) : (
            <AiFillEyeInvisible
              className="passwordIc"
              onClick={toggle}
              onMouseLeave={hidePassword}
            />
          )}
        </>
      )}
      <span className="txtSuffix">{suffix}</span>
    </span>
  );
};

const TextInput = (props) => {
  const getFile = (file, fileSize, cb) => {
    getBase64(file, (data) => {
      cb({
        fileContent: data,
        fileName: file.name,
        length: fileSize,
        fileType: file.type,
      });
    });
  };

  const handleUpload = (e) => {
    var files = e.target.files,
      f = files[0];
    const { name, onChange } = props;
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const csv = XLSX.utils.sheet_to_csv(ws);
      const csvData = csv?.split("\n").map((c) => c.split(","));
      onChange(name, csvData);
    };
    reader.readAsBinaryString(f);
  };

  const onFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    const {
      type,
      multiple,
      onChange,
      formatText: format,
      isExcel,
      minValue,
    } = props;
    if (type === "file") {
      if (isExcel) handleUpload(event);
      else {
        const selectedFiles = Array.from(target.files);
        let files = [];
        selectedFiles.forEach((file, index) => {
          const fileSize = file.size / 1024 / 1024;
          getFile(file, fileSize, (data) => {
            files.push(data);
            if (selectedFiles.length == files.length)
              onChange(name, multiple ? files : data);
          });
        });
      }
    } else {
      if (format) value = formatInputText(value);
      if (value && minValue && value < minValue) return;
      onChange(name, value);
    }
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div>
            <UserLabel {...props} />
            <Input {...props} onChange={onFieldChange} error={error} />
          </div>
        ) : (
          <div className="row m-0">
            <div className="col-sm-12 col-xs-12 col-lg-12 px-0">
              <Input {...props} onChange={onFieldChange} error={error} />
            </div>
          </div>
        )}
        <UserError error={error} />
      </div>
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  alhpaNumeric: PropTypes.bool,
  button: PropTypes.func,
  url: PropTypes.string,
  error: PropTypes.string,
  link: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  class: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  toUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
