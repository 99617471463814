import React, { useEffect, useState } from "react";
import { UserButton } from "../../../components/Button";
import { UserCheckbox } from "../../../components/Checkbox";
import { UserModal } from "../../../components/Modal";
import { TextInput } from "../../../components/TextInput";
import useUserDetail from "../../../hooks/useUserDetail";
import { RiAddCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { addSapCodes, getSapCodes } from "../../../actions/user";
import { AppConstant } from "../../../utils/constant";

const SapInput = ({
  sapCode,
  handleChange,
  index,
  isAdmin,
  lng,
  lat,
  isApproved,
  errors,
}) => {
  const onChange = (name, value) => {
    handleChange(name, value, index);
  };

  return (
    <div className="row">
      <div className="col-3">
        <TextInput
          name="sapCode"
          value={sapCode}
          onChange={onChange}
          placeholder="SAP Code"
          errors={errors}
        />
      </div>
      <div className="col-3">
        <TextInput
          name="lat"
          id="lat"
          value={lat}
          asterix
          placeholder="Latitude"
          onChange={onChange}
          errors={errors}
        />
      </div>
      <div className="col-3">
        <TextInput
          name="lng"
          id="lng"
          value={lng}
          asterix
          placeholder="Longitude"
          onChange={onChange}
          errors={errors}
        />
      </div>
      <div className="col-3">
        {isAdmin ? (
          <UserCheckbox
            name="isApproved"
            checked={isApproved}
            onChange={onChange}
            label="Approved"
          />
        ) : (
          <div className="row">
            <div className="col-12" style={{ fontSize: 14 }}>
              {isApproved ? "Approved" : "Pending for approval"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ({ show, toggle, selectedUser }) => {
  const [sapCodes, setSapCodes] = useState([{}]);
  const { isAdmin } = useUserDetail();
  const dispatch = useDispatch();
  const { sapCodes: initSapCodes } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSapCodes({ userId: selectedUser?.userProfileId }));
  }, [selectedUser]);

  useEffect(() => {
    if (initSapCodes && initSapCodes.length > 0) {
      setSapCodes(initSapCodes);
    } else {
      setSapCodes([{}]);
    }
  }, [initSapCodes]);

  const handleChange = (name, value, index) => {
    let list = sapCodes.slice();
    list[index] = { ...list[index], [name]: value };
    setSapCodes(list);
  };

  const handleAdd = () => {
    let list = sapCodes.map((c) => ({ ...c, errors: null })).slice();
    const lastIndex = list.length - 1;
    if (!list[lastIndex].sapCode) {
      list[lastIndex] = {
        ...list[lastIndex],
        errors: { sapCode: "This is required" },
      };
      setSapCodes(list);
    } else {
      if (!list[lastIndex].sapCodeId)
        handleSave(null, (isAdded) => {
          if (isAdded) {
            list.push({});
            setSapCodes(list);
          }
        });
      else {
        list.push({});
        setSapCodes(list);
      }
    }
  };

  const handleSave = (event, cb) => {
    if (sapCodes && sapCodes.every((c) => c.sapCode)) {
      dispatch(
        addSapCodes(
          {
            userProfileId: selectedUser?.userProfileId,
            sapCodes,
          },
          ({ isAdded }) => {
            if (cb) cb(isAdded);
            else if (isAdded) toggle();
          }
        )
      );
    } else {
      setSapCodes(
        sapCodes.map((c) => ({
          ...c,
          errors: c.sapCode ? null : { sapCode: "This is required" },
        }))
      );
    }
  };

  return (
    <UserModal
      btnText="Save"
      show={show}
      heading="Add SAP Code"
      onClose={toggle}
      handleClick={handleSave}
    >
      {sapCodes && (
        <>
          {sapCodes.length < AppConstant.MAX_SAP_CODE && (
            <div className="row">
              <div className="col-12">
                <UserButton
                  text="Add New"
                  icon={<RiAddCircleLine className="addIc" color="white" />}
                  onClick={handleAdd}
                  className="btn btn-primary fr"
                  showIconFirst
                />
              </div>
            </div>
          )}
          {sapCodes.map((c, index) => (
            <React.Fragment key={`SC${index}`}>
              <SapInput
                {...c}
                index={index}
                isAdmin={isAdmin}
                handleChange={handleChange}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </UserModal>
  );
};
