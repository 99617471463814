import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../../actions/article";
import { UserButton } from "../../../components/Button";
import { Footer, UserCard } from "../../../components/Card";
import { UploadFile } from "../../../components/TextInput";
import ArticleImage from "./ArticleImage";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { AppConstant } from "../../../utils/constant";

const { PAA_UNIT, CTN_UNIT } = AppConstant;

const Actions = ({
  imageMat,
  handleFile,
  handleToggle,
  handleViewDetail,
  showUpload,
  selected,
}) => {
  return (
    <Footer>
      <div className="footerContent">
        <UserButton
          icon={selected ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
          onClick={handleToggle}
        />
        <UserButton
          text="View"
          onClick={handleViewDetail}
          className="btn btn-primary btn-sm mr-l-10"
        />
        {showUpload && (
          <UploadFile fileSrcName={imageMat} onChange={handleFile} multiple />
        )}
      </div>
    </Footer>
  );
};

export default ({
  articleDetails,
  handleChange,
  showUpload,
  handleViewClick,
}) => {
  const {
    articleName,
    cname,
    dname,
    brand,
    sizeSet,
    soleMat,
    lq,
    color,
    mrp,
    selected,
    imagePath,
    imageMat,
    totalPAA,
    totalCTN,
    customField1,
  } = articleDetails;
  const dispatch = useDispatch();

  const onClick = () => {
    handleChange(imageMat, "selected", !selected);
  };

  const handleViewDetail = (event) => {
    if (event) event.stopPropagation();

    handleViewClick(articleDetails);
  };

  const handleFile = (name, files) => {
    dispatch(
      uploadImages({
        imageMat,
        files,
      })
    );
  };

  return (
    <>
      <UserCard
        className={`articleCard${selected ? " selected" : ""}`}
        onClick={onClick}
        onDoubleClick={handleViewDetail}
        footer={
          <Actions
            selected={selected}
            imageMat={imageMat}
            handleFile={handleFile}
            showUpload={showUpload}
            handleToggle={onClick}
            handleViewDetail={handleViewDetail}
          />
        }
      >
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="col-12 cardImgWrapper">
            <ArticleImage imagePath={imagePath} className="articleCardImg" />
          </div>
          <div className="col-12" style={{ paddingLeft: 20 }}>
            <div className="row">
              <div className="col-6 highlightText">{articleName}</div>
              <div className="col-6">
                {customField1 && (
                  <div className="highlightText" style={{ color: "#800000" }}>
                    {customField1}
                  </div>
                )}
              </div>
              <div className="col-6">{lq}</div>
              <div className="col-6">{color}</div>
              <div className="col-6">
                {totalPAA} {PAA_UNIT}
              </div>
              <div className="col-6">
                {totalCTN} {CTN_UNIT}
              </div>
              <div className="col-6"> Rs. {mrp}</div>
              <div className="col-6">{soleMat}</div>
              <div className="col-6">{brand}</div>
              <div className="col-6">{dname}</div>
              <div className="col-6">{cname}</div>
              <div className="col-6">{sizeSet}</div>
            </div>
          </div>
        </div>
      </UserCard>
    </>
  );
};
