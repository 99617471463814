import React, { useEffect, useRef } from "react";
import IdleTimer from "react-idle-timer";
import { useDispatch } from "react-redux";
import { signOut } from "../actions/account";
import { UserModal } from "../components/Modal";
import useForm from "../hooks/useForm";
import useToggle from "../hooks/useToggle";
import useUnload from "../hooks/useUnload";
import auth from "../libs/auth";
import { RouteConstant } from "../utils/routeConstant";

export default (props) => {
  const { state, handleStateChange } = useForm({});
  const idleRef = useRef();
  const { show, toggle } = useToggle();
  const dispatch = useDispatch();

  const onAction = (e) => {
    handleStateChange({ isTimedOut: false });
    if (!auth.isAuthenticated) {
      window.open(RouteConstant.LOGIN_SCREEN, "_self");
    }
  };

  const onActive = (e) => {
    handleStateChange({ isTimedOut: false });
  };

  const onIdle = (e) => {
    handleClose();
  };

  const handleClose = () => {
    dispatch(signOut());
  };

  const handleUnload = (ev) => {
    handleClose();
    if (ev) ev.returnValue = "";
    return "";
  };

  useUnload(handleUnload);

  return (
    <>
      <IdleTimer
        ref={idleRef}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        timeout={1000 * 60 * 30}
      />
      {show && (
        <UserModal
          btnText="Stay"
          show={show}
          heading="You have been idle!"
          onClose={handleClose}
          handleClick={toggle}
        >
          <p>You will get timed out. Do you want to stay?</p>
        </UserModal>
      )}
    </>
  );
};
