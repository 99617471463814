export const ArticleFields = [
  "plant",
  "articleName",
  "brand",
  "lq",
  "dname",
  "color",
  "cname",
  "mrp",
  "sname",
  "ind",
  "heelheight",
  "type",
  "pricestr",
  "ownership",
  "season",
  "ldate",
  "sole",
  "packing",
  // "customField1",
  "customFiedl2",
  "norm",
  "totalWip",
  "mfgstk",
  "material",
  "eanNo",
];
