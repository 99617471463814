import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import reducer from "../reducers";
import rootSaga from "../sagas";

const history = createBrowserHistory();

export default () => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, erroInfo) => {
      if (error.status == 401) {
        // store.dispatch({type:Account.SIGN_OUT});
      }
    },
  });

  const persistConfig = {
    key: "libertyRoot",
    storage,
    whitelist: ["account"],
  };

  const rootReducer = reducer(history);

  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  sagaMiddleware.run(rootSaga);

  return {
    store,
    history,
    persistor: persistStore(store),
  };
};
