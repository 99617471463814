export const AppConstant = {
  DATE_FORMAT: "dd/MM/yyyy",
  API_DATE_FORMAT: "YYYY-MM-DD hh:mm:ss",
  DATE_TIME_DISPLAY_FORMAT: "DD/MM/yyyy hh:mm A",
  DATE_DISPLAY_FORMAT: "DD/MM/yyyy",
  TIME_DISPLAY_FORMAT: "hh:mm A",
  USER_KEY: "auth-token",
  FIRST_ITEM_TEXT: "Please select",
  MAX_SAP_CODE: 10,
  UPLOAD_DOCUMENT_SIZE_LIMIT: 100, //In MB
  CURRENCY: "Rs.",

  //Status
  PENDING_STS: "Pending",
  PROCESSED_STS: "Processed",
  CANCELLED_STS: "Cancelled",

  //Units
  PAA_UNIT: "PAA",
  CTN_UNIT: "CTN",

  //BusinessTypes
  REGISTERED: "Registered",

  //UserTypes
  DISTRIBUTOR_USERTYPE: "DISTRIBUTOR",

  //ManagerEmail
  IMAGE_UPLOAD_DELETE_EMAIL: "it_knl@libertyshoes.com",

  //UserTypes
  DEALER_USER_TYPE: "DEALER",
};
