import React from "react";

export default ({ label, value }) => {
  return (
    <div className="row displayWrapper">
      <div className="col-6 capitalLabel">
        <b>{label}</b>
      </div>
      <div className="col-6">{value}</div>
    </div>
  );
};
