import React, { useEffect, useState } from "react";

const useExcel = (data, headers) => {
  const [excelData, setExcelData] = useState({
    data: [],
    columns: [],
  });

  useEffect(() => {
    if (data && headers) {
      headers = headers.filter((c) => c.key != "image");
      setExcelData({
        data: (data.some((s) => s.selected)
          ? data.filter((c) => c.selected)
          : data
        ).map((c) =>
          headers.map((h) => ({
            value: c[h.key] ? c[h.key] : "",
          }))
        ),
        columns: headers.map((h) => ({
          title: h.text ? h.text.toString() : "",
          style: { font: { sz: "12", bold: true } },
        })),
      });
    }
  }, [data, headers]);

  return {
    excelDataSet: [excelData],
  };
};

export default useExcel;
