import { push } from "connected-react-router";
import { call, put } from "redux-saga/effects";
import { Account } from "../actionTypes/account";
import { Common } from "../actionTypes/common";
import { RouteConstant } from "../utils/routeConstant";

export default function* callAPI(
  api,
  { payload, hasNextCall, cb },
  callback,
  hideLoading
) {
  try {
    if (!hideLoading) yield put({ type: Common.SHOW_PROGRESS });
    const response = yield call(api, payload);
    if (response.status >= 200 && response.status < 300) {
      const { result, success, message } = yield response.json();
      yield put({
        type: Common.SET_API_RESPONSE,
        result: { success, message },
      });
      if (!hasNextCall) yield put({ type: Common.CLOSE_PROGRESS });
      if (success) {
        if (callback) yield call(callback, result, payload, cb);
      }
      return;
    } else {
      if (!hasNextCall) yield put({ type: Common.CLOSE_PROGRESS });
      if (response.status == 401) {
        yield put({ type: Account.SIGN_OUT_SUCCESS });
        yield put(push(RouteConstant.LOGIN_SCREEN));
      } else if (response.status == 500) {
        yield put({
          type: Common.SET_API_RESPONSE,
          result: {
            message: "Request timeout.Please check your netowrk connection.",
            success: false,
          },
        });
      } else {
        yield put({ type: Common.CLOSE_PROGRESS });
        const { message } = yield response.json();
        yield put({
          type: Common.SET_API_RESPONSE,
          result: { message, success: false },
        });
      }
    }
  } catch (e) {
    yield put({ type: Common.CLOSE_PROGRESS });
    yield put({
      type: Common.SET_API_RESPONSE,
      result: { message: "Invalid request. Please try again", success: false },
    });
  }
}
