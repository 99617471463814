import React from "react";
import { Modal } from "react-bootstrap";
import { UserButton } from "../Button";

export default ({
  show,
  onClose,
  heading,
  children,
  btnText,
  handleClick,
  hideBtn,
  size
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        animation={true}
        size={`${size ? size : "lg"}`}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <UserButton
            text="Close"
            onClick={onClose}
            className="btn btn-secondary btn-sm"
          />
          {!hideBtn && <UserButton text={btnText} onClick={handleClick} />}
        </Modal.Footer>
      </Modal>
    </>
  );
};
