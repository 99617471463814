import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEditUser, addUserView } from "../../actions/user";
import { UserModal } from "../../components/Modal";
import UserSelect from "../../components/SelectBox/UserSelect";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import useUserDetail from "../../hooks/useUserDetail";
import { RegxConstant } from "../../utils/regxConstant";
import usePassword from "./usePassword";
import { AppConstant } from "../../utils/constant";
import { UserCheckbox } from "../../components/Checkbox";
import { MultiSelect } from "../../components/SelectBox";
import { includes } from "lodash";
import BrandFilter from "./BrandFilter";

const validationSchema = {
  email: {
    required: true,
    validator: {
      regEx: RegxConstant.EMAIL,
      error: "Please enter valid email.",
    },
  },
  password: {
    required: true,
    validator: {
      regEx: RegxConstant.PASSWORD,
      error:
        "Password must contain atleast 1 lowercase,1 uppercase,1 numeric and 1 special character([!@#$%^&*]).\n Password length must be 8 characters or longer.",
    },
  },
  confirmPassword: {
    required: true,
    validator: {
      regEx: RegxConstant.PASSWORD,
      error:
        "Password must contain atleast 1 lowercase,1 uppercase,1 numeric and 1 special character([!@#$%^&*]).\n Password length must be 8 characters or longer.",
    },
  },
  firstName: { required: true },
  lastName: { required: true },
  stateId: { required: true },
  cityId: { required: true },
  userTypeId: { required: true },
  managerId: { required: true },
  coordinatorId: { required: true },
  mobileNo: {
    required: true,
    validator: {
      regEx: RegxConstant.MOBILE_NO,
      error: "Please enter 10 digit mobile number.",
    },
  },
};

export default ({ show, toggle, selectedUser }) => {
  const {
    state: userDetail,
    errors,
    handleChange,
    handleStateChange,
    setState,
    handleSubmit,
    setErrors,
  } = useForm(
    {},
    {
      ...validationSchema,
      ...(selectedUser ? { password: null, confirmPassword: null } : null),
    },
    handleSave
  );
  const { isAdmin, isCoordinator } = useUserDetail();
  const [cities, setCities] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const { states, stateCities, userTypes, managerList, coordinatorList } =
    useSelector((state) => state.user);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    setState(selectedUser ? selectedUser : {});
  }, [selectedUser]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addUserView());
  }, []);

  function handleSave(userDetail) {
    const { password, confirmPassword } = userDetail;
    if (password !== confirmPassword) {
      setErrors({
        confirmPassword: "Pasword and confirm password must be same",
      });
      return;
    }
    dispatch(
      addEditUser(userDetail, () => {
        if (selectedUser) toggle();
        else
          setState({
            managerId: userDetail.managerId,
            coordinatorId: userDetail.coordinatorId,
          });
      })
    );
  }

  useEffect(() => {
    if (
      isCoordinator &&
      managerList &&
      managerList.length > 0 &&
      coordinatorList &&
      coordinatorList.length > 0
    ) {
      handleStateChange({
        managerId: managerList[0].value,
        coordinatorId: coordinatorList[0].value,
      });
    }
  }, [isCoordinator, managerList, coordinatorList]);

  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    stateId,
    cityId,
    userTypeId,
    managerId,
    coordinatorId,
    mobileNo,
    companyName,
    userProfileId,
    allowOrderModification,
  } = userDetail;

  useEffect(() => {
    if (isAdmin && managerId && coordinatorList && coordinatorList.length > 0) {
      const coordinators = coordinatorList.filter(
        (c) => c.managerId == managerId
      );
      setCoordinators(coordinators);
    }
  }, [coordinatorList, isAdmin, managerId]);

  useEffect(() => {
    if (stateId && stateCities) {
      setCities(
        stateCities
          .filter((c) => c.stateId == stateId)
          .map((c) => ({
            text: c.city,
            value: c.cityId,
          }))
      );
    } else setCities([]);
  }, [stateId, stateCities]);

  useEffect(() => {
    if (userTypes) {
      setUserType(userTypes.find((c) => c.value == userTypeId)?.text);
    }
  }, [userTypeId, userTypes]);

  usePassword(userDetail, handleStateChange);

  return (
    <UserModal
      btnText="Save"
      show={show}
      heading="Add User"
      onClose={toggle}
      handleClick={handleSubmit}
    >
      <form onSubmit={handleSubmit} method="post" className="addUserForm">
        <div className="row">
          <div className="col-6">
            <TextInput
              name="firstName"
              id="firstName"
              value={firstName}
              asterix
              placeholder="First Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
          <div className="col-6">
            <TextInput
              name="lastName"
              id="lastName"
              value={lastName}
              asterix
              placeholder="Last Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <UserSelect
              name="stateId"
              value={stateId}
              options={states}
              onChange={handleChange}
              placeholder="Select State"
              errors={errors}
            />
          </div>
          <div className="col-6">
            <UserSelect
              name="cityId"
              value={cityId}
              options={cities}
              placeholder="Select City"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <UserSelect
              name="userTypeId"
              value={userTypeId}
              options={userTypes}
              placeholder="Select User Type"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="companyName"
              id="companyName"
              value={companyName}
              placeholder="Company Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="email"
              id="email"
              value={email}
              asterix
              placeholder="Email Id"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="mobileNo"
              id="mobileNo"
              value={mobileNo}
              asterix
              placeholder="Mobile No"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="password"
              id="password"
              type="password"
              disabled={userProfileId}
              value={password}
              asterix
              placeholder="Password"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              disabled={userProfileId}
              value={confirmPassword}
              asterix
              placeholder="Confirm Password"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        {isAdmin && (
          <div className="row">
            <div className="col-6">
              <UserSelect
                name="managerId"
                id="managerId"
                value={managerId}
                options={managerList}
                placeholder="Select Manager"
                onChange={handleChange}
                errors={errors}
              />
            </div>
            <div className="col-6">
              <UserSelect
                name="coordinatorId"
                value={coordinatorId}
                options={coordinators}
                onChange={handleChange}
                placeholder="Select RSM_ASM"
                errors={errors}
              />
            </div>
          </div>
        )}
        {userType == AppConstant.DISTRIBUTOR_USERTYPE && isAdmin && (
          <div className="row">
            <div className="col-6">
              <UserCheckbox
                name="allowOrderModification"
                checked={allowOrderModification}
                onChange={handleChange}
                label="Allow Order Modifications"
              />
            </div>
          </div>
        )}
        <BrandFilter {...userDetail} handleChange={handleChange} />
      </form>
    </UserModal>
  );
};
