import { Account } from "../actionTypes/account";

export const loginUser = (payload) => ({
  type: Account.AUTH_USER,
  payload,
});

export const forgotPassword = (payload) => ({
  type: Account.FORGOT_PASSWORD,
  payload,
});

export const resetPassword = (payload) => ({
  type: Account.RESET_PASSWORD,
  payload,
});

export const signOut = (payload) => ({
  type: Account.SIGN_OUT,
  payload,
});

export const confirmAccount = (payload, cb) => ({
  type: Account.CONFIRM_ACCOUNT,
  payload,
  cb,
});

export const impersonationView = (payload, cb) => ({
  type: Account.IMPERSONATION_VIEW,
  payload,
  cb,
});

export const impersonateUser = (payload) => ({
  type: Account.IMPERSONATE_USER,
  payload,
});

export const stopImpersonation = (payload) => ({
  type: Account.STOP_IMPERSONATION,
  payload,
});
