import { takeLatest, call, put } from "redux-saga/effects";
import JobAPI from "../api/JobAPI";
import { Job } from "../actionTypes/job";
import callAPI from "./helper";

function* getJobList(action) {
  yield call(callAPI, JobAPI.getJobList, action, setResult);
}

function* triggerJob(action) {
  yield call(callAPI, JobAPI.triggerJob, action, updateJob);
}

function* updateJob(result) {
  yield put({ type: Job.UPDATE_JOB_LIST, result });
}

function* setResult(result) {
  yield put({ type: Job.JOB_LIST_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Job.GET_JOB_LIST, getJobList);
  yield takeLatest(Job.TRIGGER_JOB, triggerJob);
}
