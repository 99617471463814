import React from "react";
import { FixedSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export default ({
  rowCount,
  children,
  rowHeight,
  columnWidth,
  columnCount,
  gridRef,
}) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          rowCount={rowCount}
          rowHeight={rowHeight}
          height={height}
          width={width}
          outerRef={gridRef}
          overscanRowCount={1}
        >
          {children}
        </Grid>
      )}
    </AutoSizer>
  );
};
