import RangeSlider from "react-input-range";
import "react-input-range/src/scss/index.scss";
import { UserLabel } from "../Label";

export default function ({ min, max, name, value, onChange }) {
  const onInput = (value) => {
    onChange(name, [value.min, value.max]);
  };

  return (
    <div className="sliderWrap">
      <UserLabel label={`MRP`} />
      <RangeSlider
        minValue={min}
        maxValue={max}
        value={{ min: value[0], max: value[1] }}
        onChange={onInput}
      />
    </div>
  );
}
