import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { deleteFieldStaff, getFieldStaff } from "../../actions/user";
import useToggle from "../../hooks/useToggle";
import { DeleteModal } from "./Component";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import { includes } from "lodash";
import AddFieldStaffScreen from "./AddFieldStaffScreen";
import useUserDetail from "../../hooks/useUserDetail";

const meta = [
  {
    key: "fullName",
    text: "Name",
    sort: true,
  },
  {
    key: "mobileNo",
    text: "Mobile No",
    sort: true,
  },
  {
    key: "email",
    text: "Email Id",
    sort: true,
  },
  {
    key: "city",
    text: "City",
    sort: true,
  },
  {
    key: "state",
    text: "State",
    sort: true,
  },
  {
    key: "address",
    text: "Address",
    sort: true,
  },
  {
    key: "distributor",
    text: "Distributor",
    sort: true,
  },
  {
    key: "coordinator",
    text: "RSM_ASM",
    sort: true,
  },
  {
    key: "action",
    text: "Action",
  },
];

const Actions = ({ index, handleEdit, handleRemove, isInActive }) => {
  const onEdit = () => {
    handleEdit(index);
  };

  const onRemove = () => {
    handleRemove(index);
  };

  return (
    <>
      {isInActive ? (
        <UserButton
          className="mr-10 link text-danger"
          text="Activate"
          onClick={onRemove}
        />
      ) : (
        <>
          <UserButton className="link" text="Edit" onClick={onEdit} />
          <UserButton
            className="mr-10 link text-danger"
            text="Deactivate"
            onClick={onRemove}
          />
        </>
      )}
    </>
  );
};

export default (props) => {
  const { show, toggle, setShow } = useToggle();
  const { show: showRemove, toggle: toggleRemove } = useToggle();
  const [staffList, setStaffList] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedUser, setUser] = useState(null);
  const dispatch = useDispatch();
  const { state, handleChange } = useForm({});
  const { isCoordinator, isDistributor, isManager } = useUserDetail();

  const { searchBy } = state;

  useEffect(() => {
    dispatch(getFieldStaff());
  }, []);

  useEffect(() => {
    if (isDistributor || isCoordinator || isManager) {
      setHeaders(meta);
    } else {
      setHeaders(meta.filter((c) => c.key != "action"));
    }
  }, [isCoordinator, isDistributor, isManager]);

  const { fieldStaffList: initFieldStaffList } = useSelector(
    (state) => state.user
  );

  const handleAdd = () => {
    setUser(null);
    setShow(true);
  };

  const handleEdit = (index) => {
    setUser(staffList[index]);
    setShow(true);
  };

  const handleRemove = (index) => {
    setUser(staffList[index]);
    toggleRemove();
  };

  useEffect(() => {
    if (initFieldStaffList) {
      let users = initFieldStaffList.slice();
      if (searchBy) {
        const searchByLower = searchBy?.toLowerCase();
        users = users.filter((c) =>
          meta.some((s) => includes(c[s.key]?.toLowerCase(), searchByLower))
        );
      }
      setStaffList(users);
    }
  }, [initFieldStaffList, state]);

  const rows = useCallback(() => {
    return staffList.map((c, index) => {
      return {
        ...c,
        action: (
          <Actions
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            index={index}
            isInActive={c.isInActive}
          />
        ),
      };
    });
  }, [staffList]);

  return (
    <Container>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <TextInput
            name="searchBy"
            id="searchBy"
            value={searchBy}
            placeholder="Search by"
            onChange={handleChange}
          />
        </div>
      </div>
      {(isCoordinator || isDistributor || isManager) && (
        <div className="row">
          <div className="col-12">
            <UserButton
              className="btn btn-sm btn-primary fr"
              text="Add Field Staff"
              onClick={handleAdd}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 userTbl">
          <UserTable headers={headers} data={rows()} />
        </div>
      </div>
      {show && (
        <AddFieldStaffScreen
          show={show}
          toggle={toggle}
          selectedUser={selectedUser}
        />
      )}
      {showRemove && (
        <DeleteModal
          show={showRemove}
          toggle={toggleRemove}
          selectedUser={selectedUser}
          action={deleteFieldStaff}
        />
      )}
    </Container>
  );
};
