import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { deleteDealer, getDealers } from "../../actions/user";
import useToggle from "../../hooks/useToggle";
import { DeleteModal } from "./Component";
import useForm from "../../hooks/useForm";
import { includes, orderBy } from "lodash";
import AddDealerScreen from "./AddDealerScreen";
import useUserDetail from "../../hooks/useUserDetail";
import { formatDisplayDateTime } from "../../libs/functions";
import { UserDatePicker } from "../../components/DateControl";
import { UserSelect } from "../../components/SelectBox";

const meta = [
  {
    key: "userCode",
    text: "Dealer Code",
    sort: true,
  },
  {
    key: "fullName",
    text: "Name",
    sort: true,
  },
  {
    key: "mobileNo",
    text: "Mobile No",
    sort: true,
  },
  {
    key: "email",
    text: "Email Id",
    sort: true,
  },
  {
    key: "lat",
    text: "Latitude",
    sort: true,
  },
  {
    key: "lng",
    text: "Longitude",
    sort: true,
  },
  {
    key: "city",
    text: "City",
    sort: true,
  },
  {
    key: "state",
    text: "State",
    sort: true,
  },
  {
    key: "address",
    text: "Address",
    sort: true,
  },
  {
    key: "contactPerson",
    text: "Contact Person",
    sort: true,
  },
  {
    key: "gstinnumber",
    text: "GSTIN / PAN",
    sort: true,
  },
  {
    key: "distributor",
    text: "Distributor",
    sort: true,
  },
  {
    key: "sapCode",
    text: "SAP Code",
    sort: true,
  },
  {
    key: "coordinator",
    text: "RSM_ASM",
    sort: true,
  },
  {
    key: "createdBy",
    text: "Created By",
    sort: true,
  },
  {
    key: "createdOn",
    text: "Created On",
    sort: true,
  },
  {
    key: "action",
    text: "Action",
  },
];

const Actions = ({ index, handleEdit, handleRemove, isInActive }) => {
  const onEdit = () => {
    handleEdit(index);
  };

  const onRemove = () => {
    handleRemove(index);
  };

  return (
    <>
      {isInActive ? (
        <UserButton
          className="mr-10 link text-danger"
          text="Activate"
          onClick={onRemove}
        />
      ) : (
        <>
          <UserButton className="link" text="Edit" onClick={onEdit} />
          <UserButton
            className="mr-10 link text-danger"
            text="Deactivate"
            onClick={onRemove}
          />
        </>
      )}
    </>
  );
};

export default (props) => {
  const { show, toggle, setShow } = useToggle();
  const { show: showRemove, toggle: toggleRemove } = useToggle();
  const [dealerList, setDealerList] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedUser, setUser] = useState(null);
  const dispatch = useDispatch();
  const { state, handleChange } = useForm({
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const [distributorList, setDistributorList] = useState([]);
  const { isCoordinator, isDistributor, isFieldStaff, isManager, isAdmin } =
    useUserDetail();
  const {
    dealerList: initDealerList,
    distributorList: initDistributorList,
    states,
    coordinatorList,
  } = useSelector((state) => state.user);

  const { searchBy, dateFrom, dateTo, coordinatorId, distributorId, stateId } =
    state;

  const getDealerList = () => {
    dispatch(getDealers(state));
  };

  useEffect(() => {
    getDealerList();
  }, []);

  useEffect(() => {
    if (
      isDistributor ||
      isCoordinator ||
      isFieldStaff ||
      isManager ||
      isAdmin
    ) {
      setHeaders(meta);
    } else {
      setHeaders(meta.filter((c) => c.key != "action"));
    }
  }, [isCoordinator, isDistributor, isManager]);

  useEffect(() => {
    if (initDistributorList) {
      let distributorList = initDistributorList;
      if (coordinatorId) {
        distributorList = distributorList.filter(
          (c) => c.coordinatorId == coordinatorId
        );
      }
      setDistributorList(
        distributorList.map((c) => ({
          value: c.value,
          text: `${c.text}`,
        }))
      );
    }
  }, [initDistributorList, coordinatorId]);

  const handleAdd = () => {
    setUser(null);
    setShow(true);
  };

  const handleEdit = (index) => {
    setUser(dealerList[index]);
    setShow(true);
  };

  const handleRemove = (index) => {
    setUser(dealerList[index]);
    toggleRemove();
  };

  useEffect(() => {
    if (initDealerList) {
      let users = initDealerList.slice();
      if (searchBy) {
        const searchByLower = searchBy?.toLowerCase();
        users = users.filter((c) =>
          meta.some((s) =>
            includes(c[s.key]?.toString().toLowerCase(), searchByLower)
          )
        );
      }
      setDealerList(
        orderBy(users, [(c) => parseInt(c.userCode?.split("D")[1])], ["asc"])
      );
    }
  }, [initDealerList, state]);

  const rows = useCallback(() => {
    return dealerList.map((c, index) => {
      return {
        ...c,
        createdOn: formatDisplayDateTime(c.createdOn),
        action: (
          <Actions
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            index={index}
            isInActive={c.isInActive}
          />
        ),
      };
    });
  }, [dealerList]);

  return (
    <Container>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <UserSelect
            name="coordinatorId"
            value={coordinatorId}
            options={coordinatorList}
            placeholder="Select RSM_ASM"
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <UserSelect
            name="distributorId"
            value={distributorId}
            options={distributorList}
            placeholder="Select Distributor"
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <UserSelect
            name="stateId"
            value={stateId}
            options={states}
            onChange={handleChange}
            placeholder="Select State"
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <UserDatePicker
            name="dateFrom"
            value={dateFrom}
            handleChange={handleChange}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <UserDatePicker
            name="dateTo"
            value={dateTo}
            handleChange={handleChange}
          />
        </div>
        <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6">
          <UserButton
            text="Search"
            className="btn btn-primary btn-sm"
            onClick={getDealerList}
          />
        </div>
      </div>
      {(isCoordinator || isDistributor || isFieldStaff || isManager) && (
        <div className="row">
          <div className="col-12">
            <UserButton
              className="btn btn-sm btn-primary fr"
              text="Add Dealer"
              onClick={handleAdd}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 userTbl">
          <UserTable headers={headers} data={rows()} />
        </div>
      </div>
      {show && (
        <AddDealerScreen
          show={show}
          toggle={toggle}
          selectedUser={selectedUser}
        />
      )}
      {showRemove && (
        <DeleteModal
          show={showRemove}
          toggle={toggleRemove}
          selectedUser={selectedUser}
          action={deleteDealer}
        />
      )}
    </Container>
  );
};
