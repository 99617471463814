import { Account } from "../actionTypes/account";
import { Order } from "../actionTypes/order";
import auth from "../libs/auth";

const initialState = {
  userData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Account.AUTH_SUCCESS:
      return {
        ...state,
        ...action.result,
        isLoggedIn: true,
      };
    case Order.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          cartCount: 0,
        },
      };
    case Order.CART_SUCCESS:
      const { cartCount } = action.result;
      return cartCount >= 0
        ? {
            ...state,
            userData: {
              ...state.userData,
              cartCount,
            },
          }
        : { ...state };
    case Account.SIGN_OUT_SUCCESS:
      auth.signout();
      return {
        ...initialState,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
