import React from "react";
import { useDispatch } from "react-redux";
import Container from "../../components/Container/Container";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import { forgotPassword } from "../../actions/account";
import { UserButton } from "../../components/Button";
import UserLink from "../../components/Link/UserLink";
import { RouteConstant } from "../../utils/routeConstant";

export default (props) => {
  const dispatch = useDispatch();
  const { state, handleChange, errors, handleSubmit } = useForm(
    {},
    { email: { required: true } },
    submit
  );
  const { email } = state;

  function submit(payload) {
    dispatch(forgotPassword(payload.email));
  }

  return (
    <Container title="Forgot Password">
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-4">
            <TextInput
              name="email"
              id="email"
              value={email}
              asterix
              placeholder="Enter email"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <UserButton
              type="submit"
              text="Submit"
              className="btn btn-primary btn-sm"
            />
            <UserLink
              className="mr-l-10 btn-sm"
              text="Back to Login"
              href={RouteConstant.LOGIN_SCREEN}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};
