import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEditDealer, addDealerView } from "../../actions/user";
import { DisplayLabel } from "../../components/Label";
import { UserModal } from "../../components/Modal";
import UserSelect from "../../components/SelectBox/UserSelect";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import { RegxConstant } from "../../utils/regxConstant";
import useUserDetail from "../../hooks/useUserDetail";
import BrandFilter from "./BrandFilter";

const validationSchema = {
  firstName: { required: true },
  lastName: { required: true },
  stateId: { required: true },
  cityId: { required: true },
  lat: { required: true },
  lng: { required: true },
  distributorId: { required: true },
  sapCodeId: { required: true },
  mobileNo: {
    required: true,
    validator: {
      regEx: RegxConstant.MOBILE_NO,
      error: "Please enter 10 digit mobile number.",
    },
  },
};

export default ({ show, toggle, selectedUser }) => {
  const {
    state: userDetail,
    errors,
    handleChange,
    handleStateChange,
    setState,
    handleSubmit,
  } = useForm({}, validationSchema, handleSave);
  const { isUser, isManager } = useUserDetail();
  const [cities, setCities] = useState([]);
  const [sapCodes, setSapCodes] = useState([]);
  const [distributorList, setDistributorList] = useState([]);
  const {
    states,
    stateCities,
    distributorList: initDistributorList,
    coordinator,
    sapCodeList,
    coordinatorList,
  } = useSelector((state) => state.user);
  const {
    email,
    firstName,
    lastName,
    stateId,
    cityId,
    street1,
    street2,
    mobileNo,
    gstinnumber,
    pannumber,
    lng,
    lat,
    contactPerson,
    distributorId,
    sapCodeId,
    coordinatorId,
  } = userDetail;

  useEffect(() => {
    setState(
      selectedUser
        ? {
            ...selectedUser,
            sapCodeId: sapCodeList?.find(
              (c) => c.sapCode == selectedUser?.sapCode
            )?.sapCodeId,
          }
        : {}
    );
  }, [selectedUser, sapCodeList]);

  useEffect(() => {
    if (initDistributorList) {
      let distributorList = initDistributorList;
      if (coordinatorId) {
        distributorList = distributorList.filter(
          (c) => c.coordinatorId == coordinatorId
        );
      }
      setDistributorList(
        distributorList.map((c) => ({
          value: c.value,
          text: `${c.text}-${c.email}`,
        }))
      );
    }
  }, [initDistributorList, coordinatorId]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addDealerView());
  }, []);

  function handleSave(userDetail) {
    dispatch(
      addEditDealer(userDetail, () => {
        toggle();
      })
    );
  }

  useEffect(() => {
    if (stateId && stateCities) {
      setCities(
        stateCities
          .filter((c) => c.stateId == stateId)
          .map((c) => ({
            text: c.city,
            value: c.cityId,
          }))
      );
    } else setCities([]);
  }, [stateId, stateCities]);

  useEffect(() => {
    if (sapCodeList && distributorId) {
      setSapCodes(
        sapCodeList
          .filter((c) => c.userId == distributorId)
          .map((c) => ({
            text: c.sapCode,
            value: c.sapCodeId,
          }))
      );
    } else {
      setSapCodes([]);
    }
  }, [sapCodeList, distributorId]);

  return (
    <UserModal
      btnText="Save"
      show={show}
      heading="Add Dealer"
      onClose={toggle}
      handleClick={handleSubmit}
    >
      <form onSubmit={handleSubmit} method="post">
        {isUser && (
          <div className="row">
            <div className="col-12">
              <DisplayLabel label="RSM_ASM" value={coordinator} />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-6">
            <TextInput
              name="firstName"
              id="firstName"
              value={firstName}
              asterix
              placeholder="First Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
          <div className="col-6">
            <TextInput
              name="lastName"
              id="lastName"
              value={lastName}
              asterix
              placeholder="Last Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="email"
              id="email"
              value={email}
              asterix
              placeholder="Email Id"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="mobileNo"
              id="mobileNo"
              value={mobileNo}
              asterix
              placeholder="Mobile No"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <UserSelect
              name="stateId"
              value={stateId}
              options={states}
              onChange={handleChange}
              placeholder="Select State"
              errors={errors}
            />
          </div>
          <div className="col-6">
            <UserSelect
              name="cityId"
              value={cityId}
              options={cities}
              placeholder="Select City"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="street1"
              id="street1"
              value={street1}
              asterix
              placeholder="Street 1"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
          <div className="col-6">
            <TextInput
              name="street2"
              id="street2"
              value={street2}
              asterix
              placeholder="Street 2"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="lat"
              id="lat"
              value={lat}
              asterix
              placeholder="Latitude"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="lng"
              id="lng"
              value={lng}
              asterix
              placeholder="Longitude"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="gstinnumber"
              value={gstinnumber}
              placeholder="GSTIN Number"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="pannumber"
              value={pannumber}
              placeholder="PAN Card"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="contactPerson"
              value={contactPerson}
              placeholder="Contact Person"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          {isManager && (
            <div className="col-6">
              <UserSelect
                name="coordinatorId"
                value={coordinatorId}
                options={coordinatorList}
                placeholder="Select RSM_ASM"
                onChange={handleChange}
                errors={errors}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <UserSelect
              name="distributorId"
              value={distributorId}
              options={distributorList}
              placeholder="Select Distributor"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <UserSelect
              name="sapCodeId"
              value={sapCodeId}
              options={sapCodes}
              placeholder="Select SAP Code"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <BrandFilter {...userDetail} handleChange={handleChange} />
      </form>
    </UserModal>
  );
};
