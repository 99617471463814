import React, { useEffect, useRef, useState } from "react";
import APIUtil from "../../../api/APIUtil";
// import LazyLoad from "react-lazyload";
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";
import useOnScreen from "../../../hooks/useOnScreen";

const baseUrl = APIUtil.getBaseUrl();
const defaultImage = `${process.env.PUBLIC_URL}/assests/img/Liberty_Default.jpg`;

export default ({ imagePath, className, style, isLargeImage }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [src, setSrc] = useState(defaultImage);

  useEffect(() => {
    if (imagePath) {
      setSrc(
        isVisible || isLargeImage ? `${baseUrl}${imagePath}` : defaultImage
      );
    }
  }, [imagePath, isVisible, isLargeImage]);

  return (
    <span ref={ref}>
      {isLargeImage ? (
        <>
          <Magnifier
            imageSrc={src}
            largeImageSrc={src}
            style={style}
            className={`input-position`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultImage;
            }}
            mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK}
            touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
            dragToMove
          />
        </>
      ) : (
        <img
          src={src}
          style={style}
          className={`articleImg ${className}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
        />
      )}
    </span>
  );
};
