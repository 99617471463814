import { User } from "../actionTypes/user";

export const getManagers = (payload) => ({
  type: User.GET_MANAGERS,
  payload,
});

export const getUsers = (payload) => ({
  type: User.GET_USERS,
  payload,
});

export const addManagerView = (payload) => ({
  type: User.ADD_MANAGER_VIEW,
  payload,
});

export const addUserView = (payload) => ({
  type: User.ADD_USER_VIEW,
  payload,
});

export const addEditManager = (payload, cb) => ({
  type: User.ADD_MANAGER,
  payload,
  cb,
});

export const addEditUser = (payload, cb) => ({
  type: User.ADD_USER,
  payload,
  cb,
});

export const generatePassword = (payload, cb) => ({
  type: User.GENERATE_PASSWORD,
  payload,
  cb,
});

export const deleteManager = (payload) => ({
  type: User.DELETE_MANAGER,
  payload,
});

export const deleteUser = (payload) => ({
  type: User.DELETE_USER,
  payload,
});

export const addSapCodes = (payload, cb) => ({
  type: User.ADD_SAP_CODES,
  payload,
  cb,
});

export const getSapCodes = (payload) => ({
  type: User.GET_SAP_CODES,
  payload,
});

export const enquiryView = (payload) => ({
  type: User.ENQUIRY_VIEW,
  payload,
});

export const addEditEnquiryUser = (payload) => ({
  type: User.ADD_EDIT_ENQUIRY_USER,
  payload,
});

export const getFieldStaff = (payload) => ({
  type: User.GET_FIELD_STAFF,
  payload,
});

export const addFieldStaffView = (payload) => ({
  type: User.ADD_FIELD_STAFF_VIEW,
  payload,
});

export const addEditFieldStaff = (payload, cb) => ({
  type: User.ADD_EDIT_FIELD_STAFF,
  payload,
  cb,
});

export const deleteFieldStaff = (payload) => ({
  type: User.DELETE_FIELD_STAFF,
  payload,
});

export const getDealers = (payload) => ({
  type: User.GET_DEALERS,
  payload,
});

export const addDealerView = (payload) => ({
  type: User.ADD_DEALER_VIEW,
  payload,
});

export const addEditDealer = (payload, cb) => ({
  type: User.ADD_EDIT_DEALER,
  payload,
  cb,
});

export const deleteDealer = (payload) => ({
  type: User.DELETE_DEALER,
  payload,
});

export const getTimesheets = (payload) => ({
  type: User.GET_TIMESHEETS,
  payload,
});
