import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { generatePassword } from "../../actions/user";

const usePassword = ({ firstName, lastName, password }, handleStateChange) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !password &&
      firstName &&
      firstName.concat(lastName ? lastName : "").length >= 6
    ) {
      dispatch(
        generatePassword(firstName.concat(lastName), (password) => {
          handleStateChange({ password, confirmPassword: password });
        })
      );
    }
  }, [firstName, lastName]);
};

export default usePassword;
