import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { MultiSelect } from "../../components/SelectBox";
import { TextInput } from "../../components/TextInput";
import { deleteUser, getUsers } from "../../actions/user";
import useToggle from "../../hooks/useToggle";
import AddUserScreen from "./AddUserScreen";
import { DeleteModal, SapCode } from "./Component";
import useUserDetail from "../../hooks/useUserDetail";
import { formatDisplayDate, getMomentDate } from "../../libs/functions";
import { includes, orderBy, uniqBy } from "lodash";
import useForm from "../../hooks/useForm";

const meta = [
  {
    key: "serialNo",
    text: "S.No",
    sort: true,
  },
  {
    key: "fullName",
    text: "Name",
    sort: true,
  },
  {
    key: "city",
    text: "City",
    sort: true,
  },
  {
    key: "state",
    text: "State",
    sort: true,
  },
  {
    key: "email",
    text: "Email Id",
    sort: true,
  },
  {
    key: "userType",
    text: "User Type",
    sort: true,
  },
  {
    key: "companyName",
    text: "Company Name",
    sort: true,
  },
  {
    key: "mobileNo",
    text: "Mobile No",
    sort: true,
  },
  {
    key: "manager",
    text: "Manager",
    sort: true,
  },
  {
    key: "coordinator",
    text: "RSM_ASM",
    sort: true,
  },
  {
    key: "createdOn",
    text: "Created On",
    sort: true,
  },
  {
    key: "approved",
    text: "Approved",
    sort: true,
  },
  {
    key: "modifyOrder",
    text: "Modify Order",
    sort: true,
  },
  {
    key: "action",
    text: "Action",
  },
];

const Actions = ({
  index,
  handleEdit,
  handleRemove,
  handleSap,
  isInActive,
}) => {
  const onEdit = () => {
    handleEdit(index);
  };

  const onRemove = () => {
    handleRemove(index);
  };

  const onSap = () => {
    handleSap(index);
  };

  return (
    <>
      {!isInActive ? (
        <>
          <UserButton className="link" text="Edit" onClick={onEdit} />
          <UserButton
            className="link mr-10 text-danger"
            text="Deactivate"
            onClick={onRemove}
          />
          <UserButton className="link mr-10" text="SAP Codes" onClick={onSap} />
        </>
      ) : (
        <UserButton
          className="link mr-10 text-danger"
          text="Activate"
          onClick={onRemove}
        />
      )}
    </>
  );
};

export default (props) => {
  const dispatch = useDispatch();
  const { show, toggle, setShow } = useToggle();
  const { show: showSap, toggle: toggleSap } = useToggle();
  const { show: showRemove, toggle: toggleRemove } = useToggle();
  const { isCoordinator } = useUserDetail();
  const [users, setUsers] = useState([]);
  const [selectedUser, setUser] = useState(null);
  const [coordinators, setCoordinators] = useState([]);
  const { state, handleChange } = useForm({});

  const { coordinatorId, searchBy } = state;

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const { users: initUsers } = useSelector((state) => state.user);

  const handleAdd = () => {
    setUser(null);
    setShow(true);
  };

  const handleEdit = (index) => {
    setUser(users[index]);
    setShow(true);
  };

  const handleRemove = (index) => {
    setUser(users[index]);
    toggleRemove();
  };

  const handleSap = (index) => {
    setUser(users[index]);
    toggleSap();
  };

  useEffect(() => {
    if (initUsers) {
      let users = initUsers.slice();
      if (coordinatorId && coordinatorId.length > 0) {
        users = users.filter((c) =>
          coordinatorId.some((s) => s.value == c.coordinatorId)
        );
      }
      if (searchBy) {
        const searchByLower = searchBy?.toLowerCase();
        users = users.filter((c) =>
          meta.some((s) => includes(c[s.key]?.toLowerCase(), searchByLower))
        );
      }
      setUsers(
        orderBy(
          users.map((c) => ({
            ...c,
            modifyOrder: c.allowOrderModification ? "Yes" : "No",
          })),
          (c) => getMomentDate(c.createdOn),
          "desc"
        )
      );
    }
  }, [initUsers, state]);

  useEffect(() => {
    if (initUsers) {
      setCoordinators(
        uniqBy(initUsers, (c) => c.coordinatorId).map((c) => ({
          text: c.coordinator,
          value: c.coordinatorId,
        }))
      );
    }
  }, [initUsers]);

  const rows = useCallback(() => {
    return users.map((c, index) => {
      return {
        ...c,
        serialNo: index + 1,
        createdOn: formatDisplayDate(c.createdOn),
        action: (
          <Actions
            index={index}
            isInActive={c.isInActive}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            handleSap={handleSap}
          />
        ),
      };
    });
  }, [users]);

  return (
    <Container title="Users">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <TextInput
            name="searchBy"
            id="searchBy"
            value={searchBy}
            placeholder="Search by"
            onChange={handleChange}
          />
        </div>
        {isCoordinator ? (
          <div className="col-lg-8 col-md-8 col-sm-2 col-xs-2">
            <UserButton
              className="btn btn-sm btn-primary fr"
              text="Add User"
              onClick={handleAdd}
            />
          </div>
        ) : (
          <>
            <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
              <MultiSelect
                name="coordinatorId"
                values={coordinatorId}
                options={coordinators}
                onChange={handleChange}
                placeholder="Search RSM_ASM"
              />
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-12 userTbl">
          <UserTable headers={meta} data={rows()} />
        </div>
      </div>
      {show && (
        <AddUserScreen
          show={show}
          toggle={toggle}
          selectedUser={selectedUser}
        />
      )}
      {showSap && (
        <SapCode
          show={showSap}
          toggle={toggleSap}
          selectedUser={selectedUser}
        />
      )}
      {showRemove && (
        <DeleteModal
          show={showRemove}
          toggle={toggleRemove}
          selectedUser={selectedUser}
          action={deleteUser}
        />
      )}
    </Container>
  );
};
