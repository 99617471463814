import React, { useState } from "react";
import { UserButton } from "../../../components/Button";
import { UserModal } from "../../../components/Modal";
import useToggle from "../../../hooks/useToggle";
import ArticleImage from "./ArticleImage";

export default ({ currIndex: initIndex, articleImages, selectImage }) => {
  const { show, toggle } = useToggle();
  const [currIndex, setCurrIndex] = useState(initIndex);

  const handlePrev = () => {
    setCurrIndex(currIndex - 1);
  };

  const handleNext = () => {
    setCurrIndex(currIndex + 1);
  };

  const handleClose = () => {
    selectImage(currIndex);
    toggle();
  };

  const articleImage = articleImages ? articleImages[currIndex] : {};

  return (
    <>
      <div
        className="large-img  articleImgContainer col-lg-5 col-md-8 col-sm-8 col-xs-8"
        onClick={toggle}
      >
        {articleImage && (
          <>
            <p>
              <center>Click/tap on image to zoom</center>
            </p>
            <ArticleImage className="largeImg" {...articleImage} />
          </>
        )}
      </div>
      <UserModal
        show={show}
        onClose={handleClose}
        hideBtn
        size="xl"
        heading={
          <div className="modalHeader">
            <p>Double click/tap image to zoom in/out</p>
            <div className="actions fr">
              <UserButton
                text="Prev"
                className="btn btn-primary btn-sm "
                disabled={currIndex == 0}
                onClick={handlePrev}
              />
              <UserButton
                text="Next"
                className="btn btn-primary btn-sm mr-l-10"
                disabled={currIndex + 1 == articleImages?.length}
                onClick={handleNext}
              />
            </div>
          </div>
        }
      >
        <div className="largeImgFull">
          <ArticleImage {...articleImage} isLargeImage />
        </div>
      </UserModal>
    </>
  );
};
