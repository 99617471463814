import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { TextInput } from "../../components/TextInput";
import { impersonationView, impersonateUser } from "../../actions/account";
import { includes, orderBy } from "lodash";
import useForm from "../../hooks/useForm";

const meta = [
  {
    key: "serialNo",
    text: "S.No",
    sort: true,
  },
  {
    key: "fullName",
    text: "Name",
    sort: true,
  },
  {
    key: "userRole",
    text: "Role",
    sort: true,
  },
  {
    key: "mobileNo",
    text: "Mobile No",
    sort: true,
  },
  {
    key: "email",
    text: "Email Id",
    sort: true,
  },
  {
    key: "action",
    text: "Action",
  },
];

const Actions = ({ index, handleImpersonate }) => {
  const onClick = () => {
    handleImpersonate(index);
  };

  return (
    <>
      <UserButton className="link" text="Impersonate" onClick={onClick} />
    </>
  );
};

export default (props) => {
  const dispatch = useDispatch();
  const [initUsers, setInitUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const { state, handleChange } = useForm({});
  const { searchBy } = state;

  useEffect(() => {
    dispatch(
      impersonationView(null, ({ allUserList }) => {
        setInitUsers(allUserList);
      })
    );
  }, []);

  const handleImpersonate = (index) => {
    dispatch(impersonateUser(users[index].userName));
  };

  useEffect(() => {
    if (initUsers) {
      let users = initUsers.slice();
      if (searchBy) {
        const searchByLower = searchBy?.toLowerCase();
        users = users.filter((c) =>
          meta.some((s) => includes(c[s.key]?.toLowerCase(), searchByLower))
        );
      }
      setUsers(orderBy(users, (c) => c.fullName, "asc"));
    }
  }, [initUsers, state]);

  const rows = useCallback(() => {
    return users.map((c, index) => {
      return {
        ...c,
        serialNo: index + 1,
        action: <Actions index={index} handleImpersonate={handleImpersonate} />,
      };
    });
  }, [users]);

  return (
    <Container title="Users">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-2 col-xs-2">
          <TextInput
            name="searchBy"
            id="searchBy"
            value={searchBy}
            placeholder="Search by"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 userTbl">
          <UserTable headers={meta} data={rows()} />
        </div>
      </div>
    </Container>
  );
};
