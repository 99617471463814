import React from "react";
import { UserError, UserLabel } from "../Label";
import "./_checkbox.scss";

const Input = (props) => {
  const { name, className, disabled, onChange, checked, error } = props;

  return (
    <>
      <input
        id={name}
        type="checkbox"
        checked={checked ? true : false}
        name={`${name}`}
        onChange={onChange}
        className={`form-control ${className ? `${className}` : ""} ${
          error ? "errInput" : ""
        }`}
        disabled={disabled}
      />
      <span></span>
    </>
  );
};

const UserCheckbox = (props) => {
  const onFieldChange = (event) => {
    const { onChange, checked, name, disabled } = props;
    if (!disabled) onChange(name, !checked);
  };

  const onChange = () => {};

  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div className="form-checkbox" onClick={onFieldChange}>
            <UserLabel {...props} />
            <Input {...props} onChange={onChange} error={error} />
          </div>
        ) : (
          <div className="row m-0">
            <div
              className="col-sm-12 col-xs-12 col-lg-12 px-0"
              onClick={onFieldChange}
            >
              <Input {...props} onChange={onChange} error={error} />
            </div>
          </div>
        )}
        <UserError error={error} />
      </div>
    </div>
  );
};

export default UserCheckbox;
