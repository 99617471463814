import React from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { downloadPdf } from "../../actions/common";
import { UserButton } from "../Button";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default ({
  payload,
  filename,
  dataSet,
  sheetName,
  hasData,
  fileType,
  text,
}) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      downloadPdf({
        fileType: fileType ? fileType : "EXCEL",
        filename,
        ...payload,
      })
    );
  };

  return (
    <>
      {hasData ? (
        <div>
          <ExcelFile
            filename={filename}
            element={
              <UserButton
                text={text ? text : "Download Excel"}
                className="btn btn-primary btn-sm"
                icon={<AiOutlineFileExcel />}
              />
            }
          >
            <ExcelSheet dataSet={dataSet} name={sheetName} />
          </ExcelFile>
        </div>
      ) : (
        <UserButton
          text={text ? text : "Download Excel"}
          className="btn btn-primary btn-sm"
          icon={<AiOutlineFileExcel />}
          onClick={onClick}
        />
      )}
    </>
  );
};
