export const Article = {
  GET_ARTICLES: "GET_ARTICLES",
  UPLOAD_IMAGES: "UPLOAD_IMAGES",
  GET_ARTICLE_IAMGES: "GET_ARTICLE_IAMGES",
  SET_FILTERED_ARTICLES: "SET_FILTERED_ARTICLES",
  UPDATE_ARTICLE: "UPDATE_ARTICLE",
  DELETE_ARTICLE_IMAGE: "DELETE_ARTICLE_IMAGE",
  ARTICLE_SUCCESS: "ARTICLE_SUCCESS",
  ARTICLE_DETAIL_SUCCESS: "ARTICLE_DETAIL_SUCCESS",
};
