import React from "react";
import useUserDetail from "../../hooks/useUserDetail";
import { AiOutlineLogout } from "react-icons/ai";
import "./_header.scss";
import { RouteConstant } from "../../utils/routeConstant";
import UserLink from "../Link/UserLink";
import {
  AiOutlineBars,
  AiFillSetting,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { ImCross } from "react-icons/im";
import useScreenSize from "../../hooks/useScreenSize";
import LibertyLogo from "../../common/LibertyLogo";
import { useDispatch } from "react-redux";
import { signOut, stopImpersonation } from "../../actions/account";
import { Badge } from "react-bootstrap";
import { UserButton } from "../Button";

export default (props) => {
  const { fullName, isAdmin, isUser, cartCount, isImpersonating } =
    useUserDetail();
  const { onToggle } = props;
  const { isMobileView } = useScreenSize();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleStopImpersonation = () => {
    dispatch(stopImpersonation());
  };

  return (
    <div className="page-header md-shadow-z-1-i navbar navbar-fixed-top">
      <div className="page-logo">
        {isMobileView && (
          <div className="menu-toggler sidebar-toggler">
            <span className="toggler" onClick={onToggle}>
              <AiOutlineBars color="white" fontSize={24} />
            </span>
          </div>
        )}
        <h2 style={{ marginTop: 0, marginBottom: 0 }}>
          <LibertyLogo
            style={{ width: isMobileView ? "100%" : 200, height: 28 }}
          />
        </h2>
      </div>
      <div className="top-menu">
        <ul className="nav navbar-nav fr">
          {isImpersonating && (
            <li>
              <UserButton
                className="link"
                title="Stop Impersonation"
                icon={<ImCross color="red" fontSize={16} />}
                onClick={handleStopImpersonation}
              />
            </li>
          )}
          <li className="dropdown dropdown-user">
            <span className="username">Welcome {fullName}</span>
          </li>
          {isAdmin && (
            <li>
              <UserLink
                className="mr-l-10"
                icon={<AiFillSetting color="white" fontSize={24} />}
                href={RouteConstant.SETTING}
              />
            </li>
          )}
          {isUser && (
            <li>
              <UserLink
                className="mr-l-10"
                icon={
                  <>
                    <AiOutlineShoppingCart color="white" fontSize={24} />
                    {cartCount > 0 && (
                      <Badge bg="success" pill>
                        {cartCount}
                      </Badge>
                    )}
                  </>
                }
                href={RouteConstant.CART}
              />
            </li>
          )}
          <li className="dropdown dropdown-quick-sidebar-toggler">
            <span onClick={handleLogout} className="cursor">
              <AiOutlineLogout color="white" fontSize={24} />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
