import APIUtil from "./APIUtil";

class ArticleAPI {
  getArticles(payload) {
    return APIUtil.callPostAPI("api/Article/GetArticles", payload);
  }

  uploadImages(payload) {
    return APIUtil.callPostAPI("api/Article/UploadArticleFiles", payload);
  }

  getArticleImages(payload) {
    return APIUtil.callPostAPI("api/Article/GetArticleImages", payload);
  }

  deleteArticleImage(payload) {
    return APIUtil.callPostAPI("api/Article/DeleteArticleImage", payload);
  }
}

export default new ArticleAPI();
