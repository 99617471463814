import APIUtil from "./APIUtil";

class OnlineUserAPI {
  createOnlineUserView(payload) {
    return APIUtil.callPostAPI("api/OnlineUser/CreateOnlineUserView", payload);
  }

  validateGST(payload) {
    return APIUtil.callPostAPI("api/OnlineUser/ValidateGSTNumber", payload);
  }

  generateOTP(payload) {
    return APIUtil.callPostAPI("api/OnlineUser/GenerateOTP", payload);
  }

  validateOTP(payload) {
    return APIUtil.callPostAPI("api/OnlineUser/ValidateOtp", payload);
  }

  createOnlineUser(payload) {
    return APIUtil.callPostAPI("api/OnlineUser/AddEditOnlineUser", payload);
  }
}

export default new OnlineUserAPI();
