import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getCartDetails } from "../../actions/order";
import useUserDetail from "../../hooks/useUserDetail";
import { RouteConstant } from "../../utils/routeConstant";
import SummaryScreen from "./SummaryScreen";

export default (props) => {
  const { currentUserId } = useUserDetail();
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.order?.cartDetails);
  const history = useHistory();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    dispatch(getCartDetails(currentUserId));
  }, []);

  useEffect(() => {
    if (cartDetails)
      setArticles(
        cartDetails.map((c) => ({
          ...c,
          selected: true,
        }))
      );
  }, [cartDetails]);

  const updateCart = (imageMat, payload) => {
    let cartItems = articles.slice();
    const index = cartItems.findIndex((p) => p.imageMat == imageMat);
    if (index > -1) cartItems[index] = { ...cartItems[index], ...payload };
    setArticles(cartItems);
  };

  const handleBack = () => {
    history.push(RouteConstant.ARTICLES);
  };

  return (
    <SummaryScreen
      selectedUser={currentUserId}
      articles={articles}
      updateCart={updateCart}
      handleBack={handleBack}
    />
  );
};
