import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import useGrid from "../../hooks/useGrid";
import {
  getOrders,
  orderView,
  updateOrderStatus,
  updateOrderQuantity,
  approveOrder,
  getOrderUsers,
} from "../../actions/order";
import { UserDatePicker } from "../../components/DateControl";
import useForm from "../../hooks/useForm";
import moment from "moment";
import useUserDetail from "../../hooks/useUserDetail";
import { MultiSelect, UserSelect } from "../../components/SelectBox";
import { AppConstant } from "../../utils/constant";
import { ExportExcel, ExportTXT } from "../../components/Document";
import useCsv from "../../hooks/useCsv";
import { UserCheckbox } from "../../components/Checkbox";
import useToast from "../../hooks/useToast";
import useToggle from "../../hooks/useToggle";
import { UserModal } from "../../components/Modal";
import "./_order.scss";
import useExcel from "../../hooks/useExcel";
import { TextInput } from "../../components/TextInput";
import { sumBy, uniqBy } from "lodash";

const meta = [
  { key: "coordinator", text: "RSM/ASM" },
  { key: "status", text: "Status" },
  {
    key: "poNumber",
    text: "PO Number",
    csv: true,
  },
  {
    key: "poDate",
    text: "Date of PO",
    sort: true,
    csv: true,
  },
  {
    key: "poDateTime",
    text: "Date of PO",
  },
  {
    key: "sapCode",
    text: "Sap Code",
    sort: true,
    csv: true,
  },
  {
    key: "customer",
    text: "Customer",
    sort: true,
  },
  {
    key: "articleName",
    text: "Article",
    sort: true,
  },
  {
    key: "material",
    text: "Material Code",
    sort: true,
  },
  {
    key: "articleCode",
    text: "Article Code",
    sort: true,
    csv: true,
    addSpace: true,
  },
  {
    key: "lq",
    text: "Upper Mat",
    sort: true,
  },
  {
    key: "colorPtn",
    text: "Color",
    sort: true,
  },
  {
    key: "ind",
    text: "Indicator",
    sort: true,
  },
  {
    key: "season",
    text: "Season",
    sort: true,
  },
  {
    key: "size",
    text: "Size/Sizeroll",
    sort: true,
  },
  {
    key: "quantity",
    text: "Qty",
    sort: true,
    csv: true,
  },
  {
    key: "userCode",
    text: "Dealer Code",
    sort: true,
    csv: true,
  },
  { key: "totalPairs", text: "Total Pairs" },
  {
    key: "mrpValue",
    text: "Mrp Value \n(* Subject to revision)",
    sort: true,
  },
  {
    key: "bookedBy",
    text: "Booked By",
    sort: true,
  },
  {
    key: "dname",
    text: "Department",
    sort: true,
  },
  {
    key: "cname",
    text: "Class",
    sort: true,
  },
  {
    key: "sname",
    text: "Sub Class",
    sort: true,
  },
  {
    key: "type",
    text: "Type",
    sort: true,
  },
  {
    key: "ownership",
    text: "Ownership",
    sort: true,
  },
  {
    key: "heelheight",
    text: "Heel Height",
    sort: true,
  },
  {
    key: "packing",
    text: "Packing",
    sort: true,
  },

  {
    key: "brand",
    text: "Brand",
    sort: true,
  },
  {
    key: "sole",
    text: "Sole",
    sort: true,
  },
  { key: "manager", text: "Manager" },
  { key: "distributor", text: "Distributor" },
  { key: "remarks", text: "Remarks" },
  { key: "isApproved", text: "Approved" },
  { key: "reason", text: "Reason" },
];

const rowId = "orderId";

const Checkbox = ({ name, value, handleChange, rowId }) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return <UserCheckbox name={name} checked={value} onChange={onChange} />;
};

const Input = ({ name, value, type, placeholder, handleChange, rowId }) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return (
    <TextInput
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      minValue={1}
    />
  );
};

export default (props) => {
  const {
    gridData,
    setGridData,
    handleRowClick,
    handleChange: handleGridChange,
  } = useGrid(rowId);
  const {
    orderList,
    users: initUsers,
    coordinators: initCoordinators,
    managers,
    statusList,
    userTypes,
    cancelReasons,
    seasonList,
  } = useSelector((state) => state.order);
  const [users, setUsers] = useState({
    userList: [],
    dealerList: [],
    sapCodeList: [],
  });
  const [coordinators, setCoordinators] = useState([]);
  const {
    isCoordinator,
    isAdmin,
    isManager,
    isUser,
    allowOrderModification,
    isDistributor,
    isHeadChannelUser,
  } = useUserDetail();
  const { state, handleChange, handleStateChange } = useForm({
    startDate: moment().toDate(),
    endDate: moment().toDate(),
  });
  const dispatch = useDispatch();
  const { showError } = useToast();
  const [headings, setHeadings] = useState([]);
  const { excelData: csvData, headings: csvHeadings } = useCsv(
    gridData,
    meta.filter((c) => c.csv)
  );
  const { excelDataSet } = useExcel(gridData, headings);
  const { show: showConfirm, toggle: toggleConfirm } = useToggle();
  const { userList, sapCodeList, dealerList } = users;

  const {
    startDate,
    endDate,
    statusId,
    managerId,
    coordinatorId,
    userId,
    sapCode,
    selectAll,
    userType,
    isPending,
    totalPair,
    mrpValue,
    dealerId,
    cancelReasonId,
    seasons,
  } = state;

  const searchPayload = () => {
    let userIdList = [];
    if (userId && userId.length > 0) {
      userIdList = userId;
    }
    if (isHeadChannelUser) {
      if (dealerId && dealerId.length > 0) {
        userIdList = userIdList.concat(dealerId);
      }
    }
    if (userIdList.length == 0) {
      userIdList = userList;
      if (isHeadChannelUser) {
        userIdList = userIdList.concat(dealerList);
      }
    }

    return {
      startDate,
      endDate,
      statusId: statusId?.map((c) => c.value).join(","),
      userId: userIdList.map((c) => c.value).join(","),
      coordinatorId: coordinatorId?.map((c) => c.value).join(","),
      managerId: managerId?.map((c) => c.value).join(","),
      userType: userType?.map((c) => c.value).join(","),
      sapCode: sapCode?.map((c) => c.text).join(","),
      seasons: seasons?.join(","),
    };
  };

  useEffect(() => {
    dispatch(getOrderUsers(searchPayload()));
    handleStateChange({ userId: null, sapCode: null, dealerId: null });
  }, [startDate, endDate, statusId, managerId, coordinatorId, userType]);

  useEffect(() => {
    if (initUsers) {
      let users = initUsers;
      if (isHeadChannelUser) {
        users = users.filter((c) => c.userType != AppConstant.DEALER_USER_TYPE);
      }
      setUsers({
        userList: uniqBy(users, (c) => c.userId).map((c) => ({
          text: c.username,
          value: c.userId,
        })),
        sapCodeList: uniqBy(initUsers, (c) => c.sapCode).map((c) => ({
          text: c.sapCode,
          value: c.sapCodeId,
        })),
        dealerList: uniqBy(
          initUsers.filter((c) => c.userType == AppConstant.DEALER_USER_TYPE),
          (c) => c.userId
        ).map((c) => ({
          text: c.username,
          value: c.userId,
        })),
      });
    }
  }, [initUsers, isHeadChannelUser]);

  useEffect(() => {
    if (initCoordinators && managerId && isAdmin) {
      let coordinators = initCoordinators.filter((c) =>
        managerId.some((s) => s.value == c.parentId)
      );
      setCoordinators(coordinators);
    } else if (isAdmin) {
      setCoordinators([]);
    } else if (isManager) {
      setCoordinators(initCoordinators);
    }
    handleChange("coordinatorId", null);
  }, [managerId, initCoordinators]);

  useEffect(() => {
    if (statusList && statusList.length > 0) {
      handleChange(
        "statusId",
        statusList.filter((c) => c.text == AppConstant.PENDING_STS)
      );
    }
  }, [statusList]);

  const getOrderList = () => {
    dispatch(
      getOrders({
        ...searchPayload(),
      })
    );
  };

  useEffect(() => {
    dispatch(
      orderView({
        startDate,
        endDate,
      })
    );
  }, []);

  useEffect(() => {
    handleStateChange({
      totalPair: sumBy(gridData, (c) => c.quantity * c.packing),
      mrpValue: sumBy(gridData, (c) => c.mrpValue),
    });
  }, [gridData]);

  useEffect(() => {
    if (userList && userList.length > 0 && !orderList) {
      getOrderList();
    }
  }, [userList]);

  useEffect(() => {
    if (orderList) {
      setGridData(
        orderList.map((c) => ({
          ...c,
          selected: selectAll,
        }))
      );
    }
  }, [orderList, selectAll]);

  useEffect(() => {
    if (isCoordinator || isDistributor || isManager)
      handleChange(
        "isPending",
        !gridData.some((c) => c.selected && c.status != AppConstant.PENDING_STS)
      );
  }, [gridData]);

  useEffect(() => {
    if (orderList && orderList.length > 0) {
      const keys = Object.keys(orderList[0]);
      const headings = meta
        .filter(
          (s) =>
            keys.includes(s.key) &&
            s.key != "articleCode" &&
            s.key != "poDate" &&
            s.key != "userCode"
        )
        .slice();
      setHeadings(headings);
    }
  }, [meta, orderList]);

  const getHeaders = useCallback(() => {
    let headers = headings.slice();
    headers.splice(0, 0, {
      key: "select",
      text: (
        <UserCheckbox
          name="selectAll"
          checked={selectAll}
          onChange={handleChange}
        />
      ),
    });
    return headers;
  }, [headings, selectAll]);

  const rows = useCallback(() => {
    return gridData.map((c) => ({
      ...c,
      isApproved: c.isApproved ? "Y" : "N",
      select: (
        <Checkbox
          name="selected"
          value={c.selected}
          handleChange={handleGridChange}
          rowId={c[rowId]}
        />
      ),
      quantity:
        c.status == AppConstant.PENDING_STS && allowOrderModification ? (
          <Input
            name="quantity"
            type="number"
            value={c.quantity}
            placeholder="Quantity"
            handleChange={handleGridChange}
            rowId={c[rowId]}
          />
        ) : (
          c.quantity
        ),
    }));
  }, [gridData]);

  const validateOrders = () => {
    if (!gridData.some((c) => c.selected)) {
      showError("Please select atleat one row");
      return false;
    }
    if (gridData.some((c) => c.selected && !c.isApproved)) {
      showError("Selected orders must be approved by Distributor");
      return false;
    }

    return true;
  };

  const handleDownload = (cb) => {
    if (validateOrders()) {
      dispatch(
        updateOrderStatus({
          ...searchPayload(),
          orderIdList: gridData.filter((c) => c.selected).map((c) => c[rowId]),
          newStatusId: statusList.find(
            (c) => c.text == AppConstant.PROCESSED_STS
          )?.value,
        })
      );
      cb(true);
    } else {
      cb(false);
      return;
    }
  };

  const handleCancel = () => {
    if (validateOrders()) {
      toggleConfirm();
    }
  };

  const handleUpdate = () => {
    if (validateOrders()) {
      dispatch(
        updateOrderQuantity({
          ...searchPayload(),
          orderQuantities: gridData
            .filter((c) => c.selected)
            .map((c) => ({
              quantity: c.quantity,
              orderId: c[rowId],
            })),
        })
      );
    }
  };

  const handleApprove = () => {
    if (!gridData.some((c) => c.selected)) {
      showError("Please select atleat one row");
      return;
    }

    dispatch(
      approveOrder({
        ...searchPayload(),
        orderIdList: gridData.filter((c) => c.selected).map((c) => c[rowId]),
      })
    );
  };

  const cancelOrder = () => {
    if (!cancelReasonId) {
      showError("Please select reason.");
      return;
    }
    dispatch(
      updateOrderStatus({
        ...searchPayload(),
        orderIdList: gridData.filter((c) => c.selected).map((c) => c[rowId]),
        newStatusId: statusList.find((c) => c.text == AppConstant.CANCELLED_STS)
          ?.value,
        cancelReasonId,
      })
    );
    toggleConfirm();
  };

  return (
    <Container title="Order List" className="className orderWrapper">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <UserDatePicker
            name="startDate"
            value={startDate}
            handleChange={handleChange}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <UserDatePicker
            name="endDate"
            value={endDate}
            handleChange={handleChange}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <MultiSelect
            name="statusId"
            values={statusId}
            options={statusList}
            onChange={handleChange}
            placeholder="Select Status"
          />
        </div>
        {(isAdmin || isManager) && (
          <>
            {isAdmin && (
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <MultiSelect
                  name="managerId"
                  values={managerId}
                  options={managers}
                  onChange={handleChange}
                  placeholder="Select Manager"
                />
              </div>
            )}
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <MultiSelect
                name="coordinatorId"
                values={coordinatorId}
                options={coordinators}
                onChange={handleChange}
                placeholder="Select RSM_ASM"
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <MultiSelect
                name="userType"
                values={userType}
                options={userTypes}
                onChange={handleChange}
                placeholder="Select Vertical"
              />
            </div>
          </>
        )}
        {(!isUser || isDistributor) && (
          <>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <MultiSelect
                name="userId"
                values={userId}
                options={userList}
                onChange={handleChange}
                placeholder="Select Customer"
              />
            </div>
            {isHeadChannelUser && (
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <MultiSelect
                  name="dealerId"
                  values={dealerId}
                  options={dealerList}
                  onChange={handleChange}
                  placeholder="Select Dealer"
                />
              </div>
            )}
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <MultiSelect
                name="sapCode"
                values={sapCode}
                options={sapCodeList}
                onChange={handleChange}
                placeholder="Select Sap Code"
              />
            </div>
          </>
        )}
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <MultiSelect
            name="seasons"
            values={seasons}
            options={seasonList}
            onChange={handleChange}
            placeholder="Select Season"
            hasValues
          />
        </div>
        <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6">
          <UserButton
            text="Search"
            className="btn btn-primary btn-sm"
            onClick={getOrderList}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 fr" style={{ display: "flex" }}>
          <ExportExcel
            hasData
            sheetName="Orders"
            filename={`Order_List_${moment().format("DDMMYYYYHH:mm")}.csv`}
            dataSet={excelDataSet}
          />
          {isPending && isDistributor && (
            <UserButton
              text="Approve"
              className="btn btn-primary btn-sm fr mr-l-10"
              onClick={handleApprove}
            />
          )}
          {allowOrderModification && (
            <>
              <UserButton
                text="Update Order"
                className="btn btn-primary btn-sm fr mr-l-10"
                onClick={handleUpdate}
              />
              {isPending && (isCoordinator || isManager) && (
                <span className="mr-l-10">
                  <ExportTXT
                    list={csvData}
                    headings={csvHeadings}
                    filename={`PO_List_${moment().format("DDMMYYYYHH:mm")}.txt`}
                    onClick={handleDownload}
                    btnText="Download Quotation"
                  />
                </span>
              )}
              {isPending && (
                <UserButton
                  text="Cancel Order"
                  className="btn btn-danger btn-sm fr mr-l-10"
                  onClick={handleCancel}
                />
              )}
            </>
          )}
        </div>
      </div>
      {gridData && gridData.length > 0 && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="fr txtFontSize" style={{ display: "flex" }}>
                <b>Total pair:</b>&nbsp;{totalPair}&nbsp;&nbsp;&nbsp;
                <b>Mrp value:</b>&nbsp;{AppConstant.CURRENCY}
                {mrpValue} (* Subject to revision)
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 orderTbl">
              <UserTable
                headers={getHeaders()}
                data={rows()}
                onRowClick={handleRowClick}
                rowId={rowId}
              />
            </div>
          </div>
        </>
      )}
      {showConfirm && (
        <UserModal
          btnText="Cancel Order"
          show={showConfirm}
          heading="Cancel Order"
          onClose={toggleConfirm}
          handleClick={cancelOrder}
        >
          <div className="row">
            <div className="col-12">
              <UserSelect
                name="cancelReasonId"
                value={cancelReasonId}
                options={cancelReasons}
                onChange={handleChange}
                placeholder="Select Reason"
              />
            </div>
          </div>
        </UserModal>
      )}
    </Container>
  );
};
