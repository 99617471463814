import React from "react";
import { useDispatch } from "react-redux";
import { UserModal } from "../../components/Modal";
import { TextArea, UploadFile } from "../../components/TextInput";
import useDragging from "../../hooks/useDragging";
import useForm from "../../hooks/useForm";
import useToggle from "../../hooks/useToggle";
import { sendFeedback } from "../../actions/setting";
import { RiFeedbackFill } from "react-icons/ri";
import { UserLabel } from "../../components/Label";
import { UserButton } from "../../components/Button";

const Feedback = ({ show, onClose }) => {
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    { description: { required: true } },
    handleSave
  );
  const { description, files } = state;
  const dispatch = useDispatch();

  function handleSave(payload) {
    dispatch(sendFeedback(payload));
    onClose();
  }

  return (
    <UserModal
      show={show}
      onClose={onClose}
      heading="Send feedback to Admin"
      btnText="Send"
      handleClick={handleSubmit}
    >
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-12">
            <TextArea
              name="description"
              id="description"
              value={description}
              placeholder="Description"
              label="Description"
              onChange={handleChange}
              errors={errors}
              maxLength={1000}
            />
          </div>
          <div
            className="col-12"
            style={{ marginTop: 20, display: "flex", fontSize: 12 }}
          >
            <UserLabel label="Attach file" wrapperStyle={{ width: 100 }} />
            <UploadFile
              fileSrcName="files"
              onChange={handleChange}
              files={files}
              multiple
              showFileName
            />
          </div>
        </div>
      </form>
    </UserModal>
  );
};

export default (props) => {
  const { x, y, ref, isDragging } = useDragging({ x: 0, y: 54 });
  const { show, toggle } = useToggle();

  return (
    <>
      <div
        ref={ref}
        title="Send feedback"
        className="feedbackIc"
        style={{
          left: x > 0 ? x : "initial",
          right: 500,
          top: y,
        }}
      >
        <UserButton
          text="Send feedback"
          icon={<RiFeedbackFill size={20} color="#fffff" />}
          className="btn btn-primary btn-sm"
          onClick={toggle}
        />
      </div>
      {show && <Feedback show={show} onClose={toggle} />}
    </>
  );
};
