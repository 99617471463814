import { Article } from "../actionTypes/article";

export const getArticles = (payload) => ({
  type: Article.GET_ARTICLES,
  payload,
});

export const setFilteredArticles = (payload) => ({
  type: Article.SET_FILTERED_ARTICLES,
  payload,
});

export const getArticleDetail = (payload) => ({
  type: Article.GET_ARTICLE_DETAIL,
  payload,
});

export const getArticleImages = (payload) => ({
  type: Article.GET_ARTICLE_IAMGES,
  payload,
});

export const uploadImages = (payload) => ({
  type: Article.UPLOAD_IMAGES,
  payload,
});

export const deleteImages = (payload) => ({
  type: Article.DELETE_ARTICLE_IMAGE,
  payload,
});

export const updateArticle=(result)=>({
  type:Article.UPDATE_ARTICLE,
  result
})