import { useSelector } from "react-redux";
import { AppConstant } from "../utils/constant";
import { RoleConstant } from "../utils/roleConstant";

const useUserDetail = () => {
  const { userData } = useSelector((state) => state.account);

  return {
    isAdmin: userData?.role == RoleConstant.ADMIN,
    isManager: userData?.role == RoleConstant.MANAGER,
    isCoordinator: userData?.role == RoleConstant.SALES_COORDINATOR,
    isUser: userData?.role == RoleConstant.USER,
    isEnquiryUser: userData?.role == RoleConstant.ENQUIRY_USER,
    isFieldStaff: userData?.role == RoleConstant.FIELD_STAFF,
    fullName: userData?.userName,
    email: userData?.email,
    loginUserName: userData?.loginUserName,
    currentUserId: userData?.userProfileId,
    role: userData?.role,
    cartCount: userData?.cartCount,
    userType: userData?.userType,
    isDistributor: userData?.userType == AppConstant.DISTRIBUTOR_USERTYPE,
    showDealers: userData?.showDealers,
    isUserManager: userData?.isUserManager,
    allowOrderModification: userData?.allowOrderModification,
    isHeadChannelUser: userData?.isHeadChannelUser,
    isImpersonating: userData?.isImpersonating,
  };
};

export default useUserDetail;
