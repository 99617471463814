import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, signOut } from "../../actions/account";
import { TextInput } from "../../components/TextInput";
import { UserButton } from "../../components/Button";
import useForm from "../../hooks/useForm";
import "./_login.scss";
import UserLink from "../../components/Link/UserLink";
import { RouteConstant } from "../../utils/routeConstant";
import LibertyLogo from "../../common/LibertyLogo";
import { useHistory } from "react-router-dom";
import useUserDetail from "../../hooks/useUserDetail";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    { userName: { required: true }, password: { required: true } },
    handleLogin
  );
  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state.account);
  const { isEnquiryUser } = useUserDetail();
  const { userName, password } = state;

  useEffect(() => {
    if (isLoggedIn) {
      history.push(
        isEnquiryUser ? RouteConstant.ARTICLES : RouteConstant.ORDERS
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const userName = history.location.state?.userName;
    if (userName) {
      handleChange("userName", userName);
    }
  }, []);

  function handleLogin(payload) {
    dispatch(loginUser(payload));
  }

  return (
    <div className="container">
      <div className="loginWrapper">
        <div className="loginHeading">
          <LibertyLogo style={{ width: "100%", height: 50 }} />
        </div>
        <div className="loginBody">
          <form onSubmit={handleSubmit} method="post" className="loginForm">
            <div className="formField form-group">
              <TextInput
                name="userName"
                value={userName}
                maxLength={50}
                asterix
                placeholder="Username"
                onChange={handleChange}
                errors={errors}
                className="login-control"
              />
            </div>
            <div className="formField form-group">
              <TextInput
                name="password"
                type="password"
                value={password}
                maxLength={50}
                asterix
                placeholder="Password"
                onChange={handleChange}
                errors={errors}
                className="login-control"
              />
            </div>
            <div className="loginFooter">
              <div className="login-btn">
                <UserButton
                  type="submit"
                  text="LOGIN"
                  className="btn btn-primary"
                />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              {/* <UserLink
                className="fr mr-l-10"
                href={RouteConstant.REGISTER_USER}
                text="Sign up"
              /> */}
              <UserLink
                className="fr mr-l-10"
                href={RouteConstant.FORGOT_PASSWORD}
                text="Forgot your password?"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
