import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserButton } from "../../components/Button";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { getJobList, triggerJob } from "../../actions/job";
import { formatDisplayDateTime, formatText } from "../../libs/functions";

const meta = [
  {
    key: "status",
    text: "Status",
    sort: true,
  },
  {
    key: "createdAt",
    text: "Created At",
    sort: true,
  },
  {
    key: "jobData",
    text: "Details",
    sort: true,
  },
];

export default (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobList());
  }, []);

  const { jobList } = useSelector((state) => state.job);

  const handleTrigger = () => {
    dispatch(triggerJob());
  };

  const getRows = useCallback(() => {
    if (jobList) {
      return jobList.map((c, index) => {
        const jobData = JSON.parse(c.jobData);
        return {
          ...c,
          createdAt: formatDisplayDateTime(c.createdAt),
          jobData:
            jobData && jobData.length > 0
              ? Object.keys(jobData[0]).map((key) => (
                  <div key={key}>
                    {formatText(key)} : {jobData[0][key]}
                  </div>
                ))
              : null,
        };
      });
    }
  }, [jobList]);

  return (
    <Container>
      <div className="row">
        <div className="col-12" style={{ marginTop: 20 }}>
          <UserButton
            className="btn btn-sm btn-primary fr"
            text="Trigger Job"
            onClick={handleTrigger}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <UserTable headers={meta} data={getRows()} />
        </div>
      </div>
    </Container>
  );
};
