import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { UserError, UserLabel } from "../Label";
import { MultiSelect } from "react-multi-select-component";
import "./_selectBox.scss";
import { useEffect } from "react";
import { includes } from "lodash";

const Select = (props) => {
  const { name, placeholder, options, onChange, values, hasValues } = props;
  const [multiOptions, setMultiOptions] = useState([]);
  const [selectedValues, setValues] = useState([]);

  useEffect(() => {
    if (options) {
      setMultiOptions(options.map((c) => ({ ...c, label: c.text })));
    } else {
      setMultiOptions([]);
    }
  }, [options]);

  useEffect(() => {
    if (values) {
      if (hasValues) {
        setValues(multiOptions.filter((c) => includes(values, c.value)));
      } else {
        setValues(values.map((c) => ({ ...c, label: c.text })));
      }
    } else {
      setValues([]);
    }
  }, [multiOptions, values]);

  const onSelect = (selectedItem) => {
    if (hasValues) {
      onChange(
        name,
        selectedItem.map((c) => c.value)
      );
    } else {
      onChange(name, selectedItem);
    }
  };

  const getSelectedValues = useCallback(() => {
    if (hasValues) {
      return values
        ? multiOptions
            .filter((c) => includes(values, c.value))
            .map((c) => c.text)
            .join(",")
        : null;
    }
    return values?.map((c) => c.text).join(",");
  }, [values, multiOptions]);

  return (
    <span>
      <MultiSelect
        options={multiOptions}
        value={selectedValues}
        onChange={onSelect}
        overrideStrings={{
          selectSomeItems: placeholder,
          allItemsAreSelected: getSelectedValues(),
        }}
      />
    </span>
  );
};

const UserSelect = (props) => {
  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div>
            <UserLabel {...props} />
            <Select {...props} error={error} />
          </div>
        ) : (
          <div className="row m-0">
            <div className="col-sm-12 col-xs-12 col-lg-12 px-0">
              <Select {...props} error={error} />
            </div>
          </div>
        )}
        <UserError error={error} />
      </div>
    </div>
  );
};

UserSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default UserSelect;
