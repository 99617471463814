import APIUtil from "./APIUtil";

class OrderAPI {
  orderView(payload) {
    return APIUtil.callPostAPI("api/Order/OrderView", payload);
  }

  updateOrderStatus(payload) {
    return APIUtil.callPostAPI("api/Order/UpdateOrderStatus", payload);
  }

  updateOrderQuantity(payload) {
    return APIUtil.callPostAPI("api/Order/UpdateOrderQuantity", payload);
  }

  approveOrder(payload) {
    return APIUtil.callPostAPI("api/Order/ApproveOrder", payload);
  }

  getOrders(payload) {
    return APIUtil.callPostAPI("api/Order/GetOrderList", payload);
  }

  createOrder(payload) {
    return APIUtil.callPostAPI("api/Order/CreateOrder", payload);
  }

  addToCart(payload) {
    return APIUtil.callPostAPI("api/Order/AddToCart", payload);
  }

  removeFromCart(payload) {
    return APIUtil.callPostAPI("api/Order/RemoveFromCart", payload);
  }

  getCartDetails(payload) {
    return APIUtil.callPostAPI("api/Order/GetCartDetails", payload);
  }

  getOrderUsers(payload) {
    return APIUtil.callPostAPI("api/Order/GetOrderUsers", payload);
  }
}

export default new OrderAPI();
