import React, { useEffect, useState } from "react";

const useCsv = (data, headers) => {
  const [excelData, setExcelData] = useState({
    headings: [],
    excelData: [],
  });

  useEffect(() => {
    if (data) {
      setExcelData((prev) => ({
        ...prev,
        excelData: data.filter((c) => c.selected),
      }));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setExcelData((prev) => ({
        ...prev,
        headings: headers.map((c) => ({ ...c, label: "", key: c.key })),
      }));
    }
  }, [data]);

  return {
    ...excelData,
  };
};

export default useCsv;
