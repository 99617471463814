import React from "react";

const useUnload = (fn) => {
  React.useEffect(() => {
    window.addEventListener("unload", (ev) => {
      ev.preventDefault();
      fn();
    });
  }, []);
};

export default useUnload;
