import { takeLatest, call, put } from "redux-saga/effects";
import ArticleAPI from "../api/ArticleAPI";
import { Article } from "../actionTypes/article";
import callAPI from "./helper";

function* getArticles(action) {
  yield call(callAPI, ArticleAPI.getArticles, action, setResult);
}

function* uploadImages(action) {
  yield call(callAPI, ArticleAPI.uploadImages, action, updateArticle);
}

function* deleteImage(action) {
  yield call(
    callAPI,
    ArticleAPI.deleteArticleImage,
    action,
    setArticleDetailResult
  );
}

function* getArticleImages(action) {
  yield call(callAPI, ArticleAPI.getArticleImages, action, setResult);
}

function* updateArticle(result) {
  yield put({ type: Article.UPDATE_ARTICLE, result });
}

function* setArticleDetailResult({ imageMat, articleImages }) {
  let articleDetail;
  if (articleImages && articleImages.length > 0)
    articleDetail = { ...articleDetail, ...articleImages[0] };
  else articleDetail = { imageMat, imagePath: null };

  yield call(updateArticle, { articleDetail });
  yield put({
    type: Article.ARTICLE_DETAIL_SUCCESS,
    result: { articleImages },
  });
}

function* setResult(result) {
  yield put({ type: Article.ARTICLE_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Article.GET_ARTICLES, getArticles);
  yield takeLatest(Article.UPLOAD_IMAGES, uploadImages);
  yield takeLatest(Article.GET_ARTICLE_IAMGES, getArticleImages);
  yield takeLatest(Article.DELETE_ARTICLE_IMAGE, deleteImage);
}
