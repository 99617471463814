import { Job } from "../actionTypes/job";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Job.JOB_LIST_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    case Job.UPDATE_JOB_LIST:
      const { jobList } = action.result;
      return {
        ...state,
        ...action.result,
        jobList: addUpdateList(state.jobList, jobList, "jobId"),
      };
    default:
      return { ...state };
  }
};

const addUpdateList = (list, row, key) => {
  try {
    list = row.concat(list);
  } catch (e) {}

  return list.slice();
};
