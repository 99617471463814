import React from "react";
import PropTypes from "prop-types";
import { UserError, UserLabel } from "../Label";
import { AppConstant } from "../../utils/constant";

const Select = (props) => {
  const {
    name,
    placeholder,
    className,
    disabled,
    addFirstItem,
    value,
    options,
    error,
    onChange,
  } = props;

  return (
    <span>
      <select
        id={name}
        className={`form-control input-sm ${className ? `${className}` : ""} ${
          error ? "errInput" : ""
          }`}
        name={name}
        value={value == null ? "" : value}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {addFirstItem && (
          <option value="">{AppConstant.FIRST_ITEM_TEXT}</option>
        )}
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value} {...option.props}>
              {option.text}
            </option>
          ))}
      </select>
    </span>
  );
};

const UserSelect = (props) => {
  const onFieldChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    props.onChange(name, value);
  };

  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div>
            <UserLabel {...props} />
            <Select {...props} onChange={onFieldChange} error={error} />
          </div>
        ) : (
            <div className="row m-0">
              <div className="col-sm-12 col-xs-12 col-lg-12 px-0">
                <Select {...props} onChange={onFieldChange} error={error} />
              </div>
            </div>
          )}
        <UserError error={error} />
      </div>
    </div>
  );
};

UserSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default UserSelect;
