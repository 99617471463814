import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../components/Container";
import { UserTable } from "../../components/Table";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import { includes, uniqBy } from "lodash";
import { formatDisplayDate, formatTime } from "../../libs/functions";
import { getTimesheets } from "../../actions/user";
import { UserDatePicker } from "../../components/DateControl";
import { UserButton } from "../../components/Button";
import { MultiSelect } from "../../components/SelectBox";
import useExcel from "../../hooks/useExcel";
import { ExportExcel } from "../../components/Document";
import moment from "moment";
import APIUtil from "../../api/APIUtil";
import UserModal from "../../components/Modal/UserModal";

const baseUrl = APIUtil.getBaseUrl();

const meta = [
  { key: "image", text: "View Image" },
  {
    key: "fieldStaff",
    text: "User",
    sort: true,
  },
  {
    key: "checkInDate",
    text: "Check In(Date)",
    sort: true,
  },
  {
    key: "checkInTime",
    text: "Check In(TIME)",
    sort: true,
  },
  {
    key: "checkOutDate",
    text: "Check Out(Date)",
    sort: true,
  },
  {
    key: "checkOutTime",
    text: "Check Out(Time)",
    sort: true,
  },
  { key: "checkInAddress", text: "Check In Address" },
  { key: "checkOutAddress", text: "Check Out Address" },
  {
    key: "customerCode",
    text: "Dealer / Distributor / Showroom Code",
    sort: true,
  },
  {
    key: "distributorName",
    text: "Distributor Name",
    sort: true,
  },
  {
    key: "customer",
    text: "Dealer / Distributor / Showroom Name",
    sort: true,
  },
  {
    key: "customerCity",
    text: "City",
    sort: true,
  },
  {
    key: "customerState",
    text: "State",
    sort: true,
  },
  {
    key: "totalPairs",
    text: "Total Pairs",
    sort: true,
  },
  {
    key: "totalMrp",
    text: "Mrp Value \n(* Subject to revision)",
    sort: true,
  },
];

const officeStaffMeta = [
  { key: "image", text: "View Image" },
  {
    key: "fieldStaff",
    text: "User",
    sort: true,
  },
  {
    key: "checkInDate",
    text: "Check In(Date)",
    sort: true,
  },
  {
    key: "checkInTime",
    text: "Check In(TIME)",
    sort: true,
  },
  {
    key: "checkOutDate",
    text: "Check Out(Date)",
    sort: true,
  },
  {
    key: "checkOutTime",
    text: "Check Out(Time)",
    sort: true,
  },
  {
    key: "fieldStaffCity",
    text: "City",
    sort: true,
  },
  {
    key: "fieldStaffState",
    text: "State",
    sort: true,
  },
  { key: "checkInAddress", text: "Check In Address" },
  { key: "checkOutAddress", text: "Check Out Address" },
];

export default ({ isOfficeStaff }) => {
  const [checkInDetails, setCheckInDetails] = useState([]);
  const [fieldStaffList, setFieldStaffList] = useState([]);
  const dispatch = useDispatch();
  const { state, handleChange } = useForm({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [headers, setHeaders] = useState(
    isOfficeStaff ? officeStaffMeta : meta
  );
  const { excelDataSet } = useExcel(checkInDetails, headers);
  const { searchBy, startDate, endDate, fieldStaffId } = state;
  const [selectedUser, setSelectedUser] = useState(null);

  const getTimesheetList = () => {
    dispatch(getTimesheets({ startDate, endDate, isOfficeStaff }));
  };

  useEffect(() => {
    getTimesheetList();
  }, []);

  const { timesheets: initCheckInDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (initCheckInDetails) {
      let checkInDetails = initCheckInDetails.slice();
      if (searchBy) {
        const searchByLower = searchBy?.toLowerCase();
        checkInDetails = checkInDetails.filter((c) =>
          headers.some((s) =>
            includes(c[s.key]?.toString().toLowerCase(), searchByLower)
          )
        );
      }
      if (fieldStaffId && fieldStaffId.length > 0) {
        const fieldStaffIdList = fieldStaffId.map((c) => c.value);
        checkInDetails = checkInDetails.filter((c) =>
          includes(fieldStaffIdList, c.fieldStaffId)
        );
      }
      setCheckInDetails(
        checkInDetails.map((c) => ({
          ...c,
          location: `Latitude-${c.fieldStaffLat} \n Lonitude-${c.fieldStaffLng}`,
          checkInDate: formatDisplayDate(c.checkInDate),
          checkInTime: formatTime(c.checkInDate),
          checkOutDate: formatDisplayDate(c.checkOutDate),
          checkOutTime: formatTime(c.checkOutDate),
        }))
      );
    }
  }, [initCheckInDetails, state, fieldStaffId]);

  const rows = useCallback(() => {
    return checkInDetails.map((c) => ({
      ...c,
      image: c.fieldStaffImagePath ? (
        <UserButton
          text="View"
          onClick={() => setSelectedUser(c)}
          className="link"
        />
      ) : (
        <></>
      ),
    }));
  }, [checkInDetails]);

  useEffect(() => {
    if (initCheckInDetails) {
      setFieldStaffList(
        uniqBy(initCheckInDetails, (c) => c.fieldStaffId).map((c) => ({
          text: c.fieldStaff,
          value: c.fieldStaffId,
        }))
      );
    }
  }, [initCheckInDetails]);

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6">
            <UserDatePicker
              name="startDate"
              value={startDate}
              handleChange={handleChange}
            />
          </div>
          <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6">
            <UserDatePicker
              name="endDate"
              value={endDate}
              handleChange={handleChange}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <MultiSelect
              name="fieldStaffId"
              values={fieldStaffId}
              options={fieldStaffList}
              onChange={handleChange}
              placeholder="Select Field Staff"
            />
          </div>
          <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6">
            <TextInput
              name="searchBy"
              id="searchBy"
              value={searchBy}
              placeholder="Search by"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-1 col-lg-1 col-xs-6 col-sm-6">
            <UserButton
              text="Search"
              className="btn btn-primary btn-sm"
              onClick={getTimesheetList}
            />
          </div>
          <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6">
            <ExportExcel
              hasData
              sheetName="Timesheets"
              filename={`Timesheets_${moment().format("DDMMYYYYHH:mm")}.csv`}
              dataSet={excelDataSet}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 timesheetTable">
            <UserTable headers={headers} data={rows()} />
          </div>
        </div>
      </Container>
      {selectedUser && (
        <UserModal
          heading={selectedUser.fieldStaff}
          show
          hideBtn
          onClose={() => setSelectedUser(null)}
        >
          <div className="articleImgContainer">
            <img
              src={`${baseUrl}${selectedUser.fieldStaffImagePath}`}
              className="largeImg"
            />
          </div>
        </UserModal>
      )}
    </>
  );
};
