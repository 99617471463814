export const RouteConstant = {
  LOGIN_SCREEN: "/account/login",
  FORGOT_PASSWORD: "/account/forgotPassword",
  RESET_PASSWORD: "/account/resetPassword",
  CONFIRM_ACCOUNT: "/account/confirmAccount",
  ADD_MANAGER: "/user/addManager",
  ADD_USER: "/user/addUser",
  MANAGERS: "/user/manager",
  USERS: "/user/users",
  ARTICLES: "/articles",
  ORDERS: "/orders",
  SETTING: "/setting",
  JOBS: "/jobs",
  CART: "/cart",
  ENQUIRY: "/user/enquiry",
  REGISTER_USER: "/user/register",
  FIELD_STAFF: "/user/fieldStaff",
  DEALER: "/user/dealer",
  FIELD_STAFF_TIME_TABLE: "/fieldStaff/timeTable",
  OFFICE_STAFF_TIME_TABLE: "/staff/timeTable",
  IMPERSONATION: "/Impersonation",
};
