import React from "react";
import { TextInput } from ".";
import { FiUpload } from "react-icons/fi";

export default (props) => {
  const { onChange, multiple, fileSrcName, isExcel, files, showFileName } =
    props;

  return (
    <div className="row mx-3 form-group uploadIc">
      {showFileName && files && (
        <div className="col-6">
          <ul>
            {files.map((c, index) => (
              <li key={`F${index}`}>
                <b>{c.fileName}</b>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-6 pr-0">
        <TextInput
          className="form-control form-input hide"
          name={fileSrcName ? fileSrcName : "file"}
          type="file"
          id="file"
          value=""
          placeholder=""
          label={<FiUpload fontSize={20} />}
          labelClassName="btn btn-primary mr-l-10 fr btn-sm cursor"
          onChange={onChange}
          multiple={multiple}
          isExcel={isExcel}
        />
      </div>
    </div>
  );
};
