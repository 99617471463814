import React from "react";
import DatePicker from "react-date-picker";
import moment from "moment";
import { UserError, UserLabel } from "../Label";
import { FcCalendar } from "react-icons/fc";
import useToggle from "../../hooks/useToggle";
import { AppConstant } from "../../utils/constant";
import "./_userDatePicker.scss";

const getDate = (date) => {
  return date
    ? moment(date, [
        AppConstant.API_DATE_FORMAT,
        "YYYY-MM-DD hh:mm:ss",
        "DDMMMYYYY",
      ]).toDate()
    : undefined;
};

const UserDatePicker = ({
  value,
  onChange,
  className,
  maxDate,
  minDate,
  disabled,
}) => {
  const { show, setShow } = useToggle();

  const onClick = () => {
    setShow(true);
  };

  const handleChange = (date) => {
    onChange(date);
    setShow(false);
  };

  return (
    <div className="date-time" onClick={onClick}>
      <div className="form-section__field form-section__datepicker">
        <DatePicker
          calendarIcon={<FcCalendar />}
          isOpen={show}
          value={getDate(value)}
          onChange={handleChange}
          className={className ? `${className} form-control` : "form-control"}
          locale="en-GB"
          format="d/M/yyyy"
          maxDate={getDate(maxDate)}
          minDate={getDate(minDate)}
          autoComplete={false}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default (props) => {
  const { handleChange, errors, name, label } = props;

  const onChange = (date) => {
    date = date ? moment(date).format(AppConstant.API_DATE_FORMAT) : date;
    handleChange(name, date);
  };

  const error = errors ? errors[name] : null;

  return (
    <div className="form-group">
      <div className="form-section__field">
        {label ? (
          <div>
            <UserLabel {...props} />
            <UserDatePicker {...props} onChange={onChange} />
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-lg-12">
              <UserDatePicker {...props} onChange={onChange} />
            </div>
          </div>
        )}
        <UserError error={error} />
      </div>
    </div>
  );
};
