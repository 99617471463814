import { takeLatest, call, put } from "redux-saga/effects";
import OnlineUserAPI from "../api/OnlineUserAPI";
import { RegisterUser } from "../actionTypes/registerUser";
import callAPI from "./helper";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";

function* createOnlineUserView(action) {
  yield call(callAPI, OnlineUserAPI.createOnlineUserView, action, setResult);
}

function* validateGST(action) {
  yield call(callAPI, OnlineUserAPI.validateGST, action, action.cb);
}

function* generateOTP(action) {
  yield call(callAPI, OnlineUserAPI.generateOTP, action, setResult);
}

function* validateOTP(action) {
  yield call(callAPI, OnlineUserAPI.validateOTP, action, action.cb);
}

function* createOnlineUser(action) {
  yield call(callAPI, OnlineUserAPI.createOnlineUser, action, createUserResult);
}

function* createUserResult(result, { mobileNo }) {
  yield put(push(RouteConstant.LOGIN_SCREEN, { userName: mobileNo }));
}

function* setResult(result) {
  yield put({ type: RegisterUser.REGISTER_USER_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(RegisterUser.CREATE_ONLINE_USER_VIEW, createOnlineUserView);
  yield takeLatest(RegisterUser.VALIDATE_GST, validateGST);
  yield takeLatest(RegisterUser.GENERATE_OTP, generateOTP);
  yield takeLatest(RegisterUser.VALIDATE_OTP, validateOTP);
  yield takeLatest(RegisterUser.CREATE_ONLINE_USER, createOnlineUser);
}
