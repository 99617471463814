import React from "react";
import { useSelector } from "react-redux";

export default function Loading() {
  const { isLoading } = useSelector((state) => state.progress);

  return (
    <>
      {isLoading && (
        <div className="loading">
          <img src={`${process.env.PUBLIC_URL}/assests/img/Loading.gif`} />
        </div>
      )}
    </>
  );
}
