import { includes } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEditUserSeasons } from "../../actions/setting";
import { UserButton } from "../../components/Button";
import { MultiSelect } from "../../components/SelectBox";
import useForm from "../../hooks/useForm";

export default () => {
  const { allUsers, seasonList } = useSelector((state) => {
    return {
      allUsers: state.setting?.allUsers,
      seasonList: state.setting?.seasonList,
    };
  });
  const dispatch = useDispatch();
  const { state, handleChange, handleStateChange, handleSubmit } = useForm(
    {},
    {},
    handleSave
  );
  const { userIdList, seasons } = state;

  useEffect(() => {
    if (allUsers && userIdList) {
      handleStateChange({
        seasons: allUsers
          .filter((c) => includes(userIdList, c.value))
          .map((c) => c.seasons)
          .flat(1),
      });
    }
  }, [allUsers, userIdList]);

  function handleSave(payload) {
    dispatch(addEditUserSeasons(payload));
  }

  return (
    <>
      <h6>Season Setting</h6>
      <div className="row">
        <div className="col-6">
          <MultiSelect
            name="userIdList"
            values={userIdList}
            options={allUsers}
            onChange={handleChange}
            placeholder="Select Users"
            hasValues
          />
        </div>
        <div className="col-6">
          <MultiSelect
            name="seasons"
            values={seasons}
            options={seasonList}
            onChange={handleChange}
            placeholder="Select Seasons"
            hasValues
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <UserButton
            text="Save"
            className="btn btn-primary btn-sm"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};
