import React from "react";

const UserButton = ({
  text,
  type,
  className,
  disabled = false,
  onClick,
  icon,
  showIconFirst,
  value,
  title,
}) => {
  let btnText = showIconFirst ? (
    <span>
      {icon ? icon : ""} <span className={`btext`}>{text}</span>
    </span>
  ) : (
    <span>
      <span className={`btext`}>{text}</span> {icon ? icon : ""}
    </span>
  );
  return (
    <button
      type={type ? type : "button"}
      disabled={disabled}
      title={title}
      value={value}
      className={
        disabled
          ? "btn-disabled"
          : className
          ? className
          : "btn btn-primary btn-sm"
      }
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default UserButton;
