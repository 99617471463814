import React from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Routes from "./config/route";
import Loading from "./common/Loading";
import APIResponse from "./common/APIResponse";
import { useSelector } from "react-redux";
import { DashboardScreen } from "./containers/Home";

const App = (props) => {
  const { isLoggedIn } = useSelector((state) => state.account);
  const colVal = isLoggedIn ? 10 : 12;

  return (
    <div>
      {/* <div className="flashMessage">
        Important Information: We are upgrading our Server and hence there is a
        scheduled downtime from Saturday (22/10/2022) at 10:00 PM till Sunday
        (23/10/2022) at 10:00 AM. During this downtime you won't be able to
        Login & work on the Web portal & the Mobile App for E-Catalogue. The
        upgradation is always for better.
      </div> */}
      {isLoggedIn && <DashboardScreen />}
      <div
        className={`right-Container col-lg-${colVal} col-md-${colVal} col-sm-${colVal} col-xs-${colVal}`}
      >
        <Routes {...props} />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <APIResponse />
      <Loading />
    </div>
  );
};

export default withRouter(App);
