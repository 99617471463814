import { Account } from "../actionTypes/account";
import { Article } from "../actionTypes/article";

const initialState = {
  // articles: [
  //   {
  //     imageMat: "1004013156",
  //     articleId: 102175,
  //   },
  // ],
  // allArticles: [
  //   {
  //     imageMat: "1004013156",
  //   },
  // ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Article.GET_ARTICLE_IAMGES:
      return {
        ...state,
        articleImages: null,
      };
    case Article.ARTICLE_SUCCESS:
    case Article.ARTICLE_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    case Article.UPDATE_ARTICLE:
      const { articleDetail } = action.result;
      return {
        ...state,
        articles: updateArticles(state.articles, articleDetail, "imageMat"),
      };
    case Article.SET_FILTERED_ARTICLES:
      return {
        ...state,
        ...action.payload,
      };
    case Account.SIGN_OUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

const updateArticles = (list, row, id) => {
  const index = list.findIndex((c) => c[id] == row[id]);
  if (index > -1) {
    list[index] = { ...list[index], ...row };
  }

  return list.slice();
};
