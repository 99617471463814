import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { confirmAccount } from "../../actions/account";
import { Container } from "../../components/Container";
import UserLink from "../../components/Link/UserLink";
import { RouteConstant } from "../../utils/routeConstant";

export default (props) => {
  const dispatch = useDispatch();
  const { email, token } = useParams();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    dispatch(
      confirmAccount({ email, token }, () => {
        setIsActive(true);
      })
    );
  }, []);

  return (
    <>
      {isActive && (
        <Container>
          <h2>Thankyou !!</h2>
          <span>Your account is active now.</span>
          <UserLink
            className="mr-l-10 btn-sm"
            text="Back to Login"
            href={RouteConstant.LOGIN_SCREEN}
          />
        </Container>
      )}
    </>
  );
};
