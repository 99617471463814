import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { UserButton } from "../Button";
import { downloadFileFromBlob } from "../../libs/functions";

export default ({ list, filename, btnText, onClick, headings }) => {
  const handleDownload = () => {
    onClick((download) => {
      if (download && list.length > 0) {
        var textString = "";
        list.forEach((l) => {
          headings.forEach((h) => {
            const text = l[h.key];
            textString += (text ? text : "") + "\t" + (h.addSpace ? "\t" : "");
          });
          textString += "\n";
        });
        downloadFileFromBlob(
          new Blob([textString], {
            type: "text/plain",
          }),
          filename
        );
      }
    });
  };

  return (
    <UserButton
      text={btnText}
      className="btn btn-primary btn-sm fr"
      icon={<AiOutlineFileText />}
      onClick={handleDownload}
    />
  );
};
