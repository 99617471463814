import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addManagerView, addEditManager } from "../../actions/user";
import { UserModal } from "../../components/Modal";
import UserSelect from "../../components/SelectBox/UserSelect";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import useToggle from "../../hooks/useToggle";
import { RegxConstant } from "../../utils/regxConstant";
import { RoleConstant } from "../../utils/roleConstant";
import BrandFilter from "./BrandFilter";
import usePassword from "./usePassword";

const validationSchema = {
  email: {
    required: true,
    validator: {
      regEx: RegxConstant.EMAIL,
      error: "Please enter valid email.",
    },
  },
  password: {
    required: true,
    validator: {
      regEx: RegxConstant.PASSWORD,
      error:
        "Password must contain atleast 1 lowercase,1 uppercase,1 numeric and 1 special character([!@#$%^&*]).\n Password length must be 8 characters or longer.",
    },
  },
  firstName: { required: true },
  lastName: { required: true },
  roleId: { required: true },
  mobileNo: {
    required: true,
    validator: {
      regEx: RegxConstant.MOBILE_NO,
      error: "Please enter 10 digit mobile number.",
    },
  },
};

export default ({ show, toggle, selectedUser }) => {
  const {
    state,
    errors,
    handleChange,
    handleStateChange,
    handleSubmit,
    setState,
    setErrors,
  } = useForm(
    {},
    {
      ...validationSchema,
      ...(selectedUser ? { password: null, confirmPassword: null } : null),
    },
    handleSave
  );
  const { show: showManager, setShow: toggleManager } = useToggle(false);
  const [managerList, setManagerList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addManagerView());
  }, []);

  useEffect(() => {
    setState(selectedUser ? selectedUser : {});
  }, [selectedUser]);

  const { roles, managerList: initManagerList } = useSelector(
    (state) => state.user
  );

  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    roleId,
    mobileNo,
    managerId,
    userProfileId,
  } = state;

  useEffect(() => {
    if (initManagerList) {
      setManagerList(initManagerList.filter((c) => c.value != userProfileId));
    }
  }, [initManagerList, userProfileId]);

  function handleSave(userDetail, { showManager }) {
    const { managerId, password, confirmPassword } = userDetail;
    if (showManager && !managerId) {
      setErrors({ managerId: "This is required field" });
      return;
    }
    if (password !== confirmPassword) {
      setErrors({
        confirmPassword: "Pasword and confirm password must be same",
      });
      return;
    }
    dispatch(
      addEditManager(userDetail, () => {
        if (selectedUser) toggle();
        else setState({});
      })
    );
  }

  const handleAddEdit = (event) => {
    handleSubmit(event, { showManager });
  };

  useEffect(() => {
    if (roles) {
      if (
        roleId &&
        roles.find((c) => c.value == roleId)?.text ==
          RoleConstant.SALES_COORDINATOR_DISPLAY
      ) {
        toggleManager(true);
        handleChange("managerId", selectedUser?.managerId);
      } else {
        toggleManager(false);
        handleChange("managerId", null);
      }
    }
  }, [roleId, roles, selectedUser]);

  usePassword(state, handleStateChange);

  return (
    <UserModal
      btnText="Save"
      show={show}
      heading="Add User"
      onClose={toggle}
      handleClick={handleAddEdit}
    >
      <form onSubmit={handleAddEdit} method="post">
        <div className="row">
          <div className="col-6">
            <TextInput
              name="firstName"
              id="firstName"
              value={firstName}
              asterix
              placeholder="First Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
          <div className="col-6">
            <TextInput
              name="lastName"
              id="lastName"
              value={lastName}
              asterix
              placeholder="Last Name"
              onChange={handleChange}
              errors={errors}
              formatText
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="email"
              id="email"
              value={email}
              asterix
              placeholder="Email Id"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="mobileNo"
              id="mobileNo"
              value={mobileNo}
              asterix
              placeholder="Mobile No"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              name="password"
              id="password"
              type="password"
              value={password}
              disabled={userProfileId}
              asterix
              placeholder="Password"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              disabled={userProfileId}
              asterix
              placeholder="Confirm Password"
              onChange={handleChange}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <UserSelect
              name="roleId"
              value={roleId}
              options={roles}
              onChange={handleChange}
              placeholder="Select role"
              errors={errors}
            />
          </div>
          {showManager && (
            <div className="col-6">
              <UserSelect
                name="managerId"
                value={managerId}
                options={managerList}
                placeholder="Select Manager"
                onChange={handleChange}
                errors={errors}
              />
            </div>
          )}
        </div>
        <BrandFilter {...state} handleChange={handleChange} />
      </form>
    </UserModal>
  );
};
