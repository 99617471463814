import React from "react";
import { useDispatch } from "react-redux";
import { UserModal } from "../../../components/Modal";
import { formatText } from "../../../libs/functions";

export default ({ show, toggle, action, selectedUser }) => {
  const dispatch = useDispatch();
  const inActiveText = selectedUser?.isInActive ? "activate" : "deactivate";
  const handleRemove = () => {
    dispatch(action(selectedUser?.userProfileId));
    toggle();
  };

  return (
    <UserModal
      btnText={`${formatText(inActiveText)}`}
      show={show}
      heading={`${formatText(inActiveText)} User`}
      onClose={toggle}
      handleClick={handleRemove}
    >
      Are you sure you want to {inActiveText} user?
    </UserModal>
  );
};
