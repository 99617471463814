import APIUtil from "./APIUtil";

class SettingAPI {
  getAppConstant(payload) {
    return APIUtil.callPostAPI("api/User/GetAppConstant", payload);
  }

  addAppConstant(payload) {
    return APIUtil.callPostAPI("api/User/AddAppConstant", payload);
  }

  addEditUserSeasons(payload) {
    return APIUtil.callPostAPI("api/User/AddEditUserSeasons", payload);
  }

  sendFeedback(payload) {
    return APIUtil.callPostAPI("api/User/SendFeedback", payload);
  }
}

export default new SettingAPI();
